<script>
import PlayerMixin from "./ByskPlayer";

import { delay, asleep } from "@/js/common";
import { uuid } from "@/utils/uuid";

export default {
  name: "ByskPlayerRecord",
  mixins: [PlayerMixin],
  // extends: PlayerMixin,
  data() {
    return {
      id: "record",
      flvAudio: null
    };
  },
  computed: {
    isRecord() {
      return true;
    },
    record() {
      return this.byskFlv.record;
    }
  },
  beforeMount() {},
  beforeDestroy() {},
  errorCaptured(error, vm, info) {
    console.log(error, vm, info);
  },
  methods: {
    query(params, cb) {
      const {
        device,
        channel,
        begintime,
        endtime,
        alarmSign,
        protocolType,
        uuid
      } = params;
      this.record.query(
        { device, channel, begintime, endtime, alarmSign, protocolType },
        (res) => {
          if (cb) cb({ ...res, uuid });
        }
      );
    },
    open(ids, params, cb, flvMds = {}, flvConfig = {}) {
      ids = ids instanceof Array ? [...ids] : [ids];
      let {
        device,
        channel,
        begintime,
        endtime,
        seeTime,
        fileName,
        protocolType,
        storgetype,
        playway,
        playspeed,
        codetype,
        datatype,
        plate,
        vehicleId,
        groupId,
        SpecialSign
      } = params;
      const channels = (channel + "").split(",");
      let players = this.players.filter((p) => ids.indexOf(p.videoId) > -1);
      this.timing();

      // endtime--;

      flvMds = this.createFlvMDS(flvMds);

      let uid = uuid();
      players.forEach((player, i) => {
        this.endVideoRecord(player);
        player.uid = uid;
        player.device = device;
        player.channel = channels[i] * 1;
        player.protocolType = protocolType;
        player.datatype = datatype;
        player.codetype = codetype;
        player.begintime = begintime;
        player.seekTime = seeTime || begintime;
        player.endtime = endtime;
        player.fileName = fileName;
        player.storgetype = storgetype;
        player.playway = playway;
        player.playspeed = playspeed;
        player.plate = plate;
        player.groupId = groupId;
        player.vehicleId = vehicleId;
        player.using = true;
        player.paused = false;
        player.replayBtnHide = true;
        player.networkPaused = false;
        player.currentTime = 0;
        player.downloadSpeed = 0;
        player.splaytime = 0;
        player.progress = 0;
        player.progressText = "";
        player.flvMds = flvMds;
        player.poster = this.poster;
        player.codetypeSwitching = false;
        player.SpecialSign = SpecialSign;
      });

      const start = (count) => {
        players = players.filter((p) => p.using && p.uid === uid);
        if (!players.length) return;
        players.forEach((player) => {
          player.logs.clear();
          player.logs.push(
            `正在请求视频${["...", "....", "....."][count - 1]}`
          );
          player.currentTime = 0;
        });
        delay(
          () => {
            players = players.filter((p) => p.using && p.uid === uid);
            if (!players.length) return;
            this.record.open(
              players.map((p) => p.video),
              {
                device,
                channel: players.map((p) => p.channel).join(","),
                begintime,
                endtime,
                fileName,
                storgetype,
                playway,
                playspeed,
                protocolType,
                datatype,
                codetype,
                SpecialSign
              },
              (res) => {
                players = players.filter((p) => p.using && p.uid === uid);
                players.forEach((player) => player.logs.push(res.info));
                if (
                  [-1, -2, -6, -7, -8, -102].includes(res.status) &&
                  count < 3
                ) {
                  start(++count);
                  return;
                }
                players.forEach((player) => {
                  if ([1, 2].indexOf(res.status) < 0) {
                    player.uid = "";
                    player.using = false;
                    player.paused = true;
                    player.video.muted = true;
                    player.replayBtnHide = false;
                    player.video = null;
                    player.networkPaused = true;
                    player.currentTime = 0;
                    player.downloadSpeed = 0;
                    player.splaytime = 0;
                    player.videoSrc = null;
                    player.progress = 0;
                    player.codetypeSwitching = false;
                  } else if (res.status === 1) {
                    //TODO 数据加载完成还是显示
                    player.logs.push("设备响应成功,视频加载中...");
                    player.replayBtnHide = true;
                    //// player.logs.clear()
                  } else if (res.status === 2) {
                    player.videoSrc = res.media_address.video_address;
                  }
                });

                if (cb) cb(res);
              },
              flvMds,
              flvConfig
            );
          },
          count > 1 ? 2000 : 0
        );
      };

      start(1);
    },
    close(el, params, cb) {
      const { device, channel, protocolType, replayBtnHide=false } = params;
      let player = this.players.find(
        (p) =>
          p.device === device &&
          p.channel === channel &&
          p.protocolType === protocolType &&
          p.using
      );

      this.endVideoRecord(player);

      if (this.flvAudio) {
        this.byskFlv.flvDestroy(this.flvAudio);
        this.flvAudio = null;
      }

      this.record.close(el, { device, channel, protocolType }, cb);

      if (player) {
        player.uid = "";
        player.paused = true;
        player.networkPaused = true;
        player.muted = true;
        player.using = false;
        player.replayBtnHide = replayBtnHide;
        player.downloadSpeed = 0;
        player.currentTime = 0;
        player.splaytime = 0;
        player.progress = 0;
        player.poster = this.poster;
        player.codetypeSwitching = false;

        player.logs.clear();
        if (player.video) {
          player.video.removeAttribute("vid");
          player.video.muted = true;
          player.video = null;
        }
      }
    },
    play(params, cb) {
      const { device, channel, protocolType } = params;
      this.record.play({ device, channel, protocolType }, cb);
      let player = this.players.find(
        (p) =>
          p.device === device &&
          p.channel === channel &&
          p.protocolType === protocolType &&
          p.using
      );
      if (player) {
        player.paused = false;
        player.networkPaused = false;
        if (player.video) {
          player.video.play();
        }
      }
    },
    pause(params, cb) {
      const { device, channel, protocolType } = params;
      let player = this.players.find(
        (p) =>
          p.device === device &&
          p.channel === channel &&
          p.protocolType === protocolType &&
          p.using
      );
      if (player) {
        player.paused = true;
        player.networkPaused = true;
        if (player.video) {
          player.video.pause();
        }
      }
      this.record.play({ device, channel, protocolType }, cb);
    },

    // 倍数播放
    forward(params, cb) {
      const { device, channel, protocolType, playspeed } = params;

      this.record.forward(
        {
          device,
          channel,
          protocolType,
          playspeed
        },
        cb
      );

      let player = this.players.find(
        (p) =>
          p.device === device &&
          p.channel === channel &&
          p.protocolType === protocolType &&
          p.using
      );

      if (player) {
        player.playspeed = playspeed;
      }
    },
    // 关键帧快退回放
    backward(params, cb) {
      const { device, channel, protocolType, playspeed } = params;

      this.record.backward(
        {
          device,
          channel,
          protocolType,
          playspeed
        },
        cb
      );

      let player = this.players.find(
        (p) =>
          p.device === device &&
          p.channel === channel &&
          p.protocolType === protocolType &&
          p.using
      );

      if (player) {
        player.playspeed = playspeed;
      }
    },

    // 关键帧播放
    keyframe(params, cb) {
      const { device, channel, protocolType } = params;

      this.record.keyframe(
        {
          device,
          channel,
          protocolType
        },
        cb
      );

      let player = this.players.find(
        (p) =>
          p.device === device &&
          p.channel === channel &&
          p.protocolType === protocolType &&
          p.using
      );

      if (player) {
        player.playspeed = 1;
      }
    },

    onCtrlMuted(item) {
      delay(() => {
        if (item.muted) {
          //静音
          if (item.audio) item.audio.muted = true;
          if (item.video) item.video.muted = true;
        } else {
          //其他播放器静音
          this.players
            .slice(0, this.players.length - 1)
            .filter((p) => p.id !== item.id && !p.muted)
            .forEach((p) => {
              p.muted = true;
              if (p.audio) p.audio.muted = true;
              if (p.video) p.video.muted = true;
            });
          if (item.flvMds.hasAudio) {
            if (item.video) item.video.muted = false;
          } else {
            //音频视频分离
            if (!(item.paused || item.audio.src)) {
              this.flvAudio = this.byskFlv.flvStart(item.audio, {
                hasAudio: true,
                hasVideo: false,
                url: item.videoSrc.replace(".media", ".sound")
              });
              this.flvAudio.muted = item.muted;
            }
            if (item.audio) item.audio.muted = item.muted;
          }
        }
      }, 200);
    },
    onClickCtrlStop(item) {
      const {
        device,
        channel,
        begintime,
        endtime,
        fileName,
        storgetype,
        playway,
        playspeed,
        protocolType,
        plate,
        vehicleId,
        groupId,
        using,
        stopCtrlBtnEnabled
      } = item;
      if (device && channel && using && stopCtrlBtnEnabled) {
        this.$emit("playend", {
          device,
          channel,
          begintime,
          endtime,
          fileName,
          storgetype,
          playway,
          playspeed,
          protocolType,
          plate,
          vehicleId,
          groupId
        });
        this.close(null, { device, channel, protocolType }, (res) => {});
      }
    },
    handleReplay(item) {
      const {
        videoId,
        device,
        channel,
        begintime,
        endtime,
        fileName,
        protocolType,
        storgetype,
        playway,
        playspeed,
        plate,
        vehicleId,
        groupId,
        flvMds,
        datatype,
        codetype,
        SpecialSign
      } = item;
      if (device && channel) {
        if (
          this.players.some(
            (p) => p.using && p.device === device && p.channel === channel
          )
        ) {
          item.logs.clear();
          item.logs.push("该设备通道已在播放");
          return;
        }
        this.open(
          videoId,
          {
            device,
            channel,
            protocolType,
            begintime,
            endtime,
            fileName,
            storgetype,
            playway,
            playspeed,
            datatype,
            codetype,
            plate,
            vehicleId,
            groupId,
            SpecialSign
          },
          (res) => {
            if ([1, 2].indexOf(res.status) < 0) {
              this.$emit("playend", {
                device,
                channel,
                begintime,
                endtime,
                fileName,
                storgetype,
                playway,
                playspeed,
                protocolType,
                plate,
                vehicleId,
                groupId
              });
            } else {
              if (res.status === 2) {
                this.$emit("playstart", {
                  vid: videoId,
                  device,
                  channel,
                  begintime,
                  endtime,
                  fileName,
                  storgetype,
                  playway,
                  playspeed,
                  protocolType,
                  plate,
                  vehicleId,
                  groupId
                });
              }
            }
          },
          flvMds
        );
      }
    },
    onClickPlayer(item) {
      const {
        device,
        channel,
        begintime,
        endtime,
        fileName,
        storgetype,
        playway,
        playspeed,
        protocolType,
        plate,
        vehicleId,
        groupId
      } = item;
      this.$emit("click", {
        device,
        channel,
        begintime,
        endtime,
        fileName,
        storgetype,
        playway,
        playspeed,
        protocolType,
        plate,
        vehicleId,
        groupId
      });
    },
    // 拖动进度条
    onInputProgress(value, item) {
      if (!(item && item.using)) return;
      const { begintime, endtime, currentTime } = item;
      if (value < begintime || value > endtime) return;

      item.isDragging = true;

      const duration = endtime - begintime;
      const time = value - begintime + currentTime;
      item.progress = Number(((time / duration) * 100).toFixed(2));
      item.progressText = `${this.timeFormat(~~time * 1000)}/${this.timeFormat(
        ~~duration * 1000
      )}`;
    },
    // 拖动结束
    async onChangeProgress(value, item) {
      if (!(item && item.using)) return;
      const {
        videoId,
        device,
        channel,
        begintime,
        endtime,
        fileName,
        protocolType,
        storgetype,
        playway,
        playspeed,
        plate,
        vehicleId,
        groupId,
        flvMds,
        datatype,
        codetype,
        SpecialSign,
      } = item;

      if (value < begintime || value > endtime) return;

      console.log("draged seektime", value, new Date(value * 1000));
      item.isDragging = false;
      item.seekTime = value;
      item.seekpos = value;
      item.currentTime = 0;
      item.paused = true;
      if (item.video) {
        item.video.pause();
      }
      this.setProgress(item);

      // 拖动是通过停止当前播放, 计算当前进度
      // 再通过重新请求播放实现
      // 从停止到重新请求播放, 中间有一段空隙,
      // 通过拖动的最后一帧画面, 填补这段空隙

      // 切换拖动的最后一帧,
      const dataURL = this.snapshot(item);
      item.poster = dataURL;

      // 有播放声音, 先关闭声音
      if (this.flvAudio) {
        this.byskFlv.flvDestroy(this.flvAudio);
        this.flvAudio = null;
      }

      // this.record.close(null, {
      //   device,
      //   channel,
      //   protocolType,
      //   forced: false
      // });

      // item.logs.clear();
      // this.record.ctrl({
      //     device,
      //     channel,
      //     // 计算拖动时间, 再以这个时间为开始时间请求视频
      //     begintime: item.seekTime,
      //     endtime,
      //     fileName,
      //     storgetype,
      //     playway,
      //     playspeed,
      //     protocolType,
      //     datatype,
      //     codetype,
      //     SpecialSign,
      //     seekpos: item.seekpos,
      //     ctrlcmd: 5
      // });
      // return false;
      item.logs.push(`正在请求视频...`);

      await asleep(200);

      item.paused = false;
      this.record.open(
        item.video,
        {
          device,
          channel,
          // 计算拖动时间, 再以这个时间为开始时间请求视频
          begintime: item.seekTime,
          endtime,
          fileName,
          storgetype,
          playway,
          playspeed,
          protocolType,
          datatype,
          codetype,
          SpecialSign,
          seekpos: item.seekpos,
          ctrlcmd: 5
        },
        (res) => {
          item.logs.push(res.info);

          if ([1, 2].indexOf(res.status) < 0) {
            item.uid = "";
            item.using = false;
            item.paused = true;
            item.video.muted = true;
            item.replayBtnHide = false;
            item.video = null;
            item.networkPaused = true;
            item.currentTime = 0;
            item.downloadSpeed = 0;
            item.splaytime = 0;
            item.videoSrc = null;
            item.isDragging = false;
            item.poster = this.poster;
          } else if (res.status === 1) {
            item.logs.push("设备响应成功,视频加载中...");
            asleep(1500).then(() => {
              item.poster = this.poster;
              item.logs.clear();
            });
          } else if (res.status === 2) {
            item.videoSrc = res.media_address.video_address;
            asleep(2000).then(() => (item.isDragging = false));
          }
        },
        flvMds
      );
    },
    videoEventHandler(e, item) {
      switch (e.type) {
        case "canplay":
        case "playing":
        case "canplaythrough":
          if (document[this.getVisibilityProperty().hidden] && item.video) {
            item.video.play();
            this.changeVideoCurrentTime(item);
          }
          break;
        case "timeupdate":
        case "progress":
          if (item.video) {
            if (item.video.paused) item.video.play();
            this.changeVideoCurrentTime(item);
          }
          break;
      }
    },
    changeVideoCurrentTime(item) {
      let { device, channel, protocolType, networkPaused, begintime, endtime } =
        item;
      let { buffered, currentTime, playbackRate } = item.video;
      if (buffered.length > 0) {
        const end = buffered.end(0);
        if (end < currentTime) {
          item.video.currentTime = end - 0.1;
        } else if (item.currentTime === currentTime && end - currentTime > 5) {
          //缓冲区大于5秒数据，但播放进度不变
          item.video.currentTime += 0.3;
        } else if (end - currentTime > 30 && !networkPaused) {
          // 缓冲区大于30秒数据，并且视频服务正在推流，则暂停推流
          // item.networkPaused = true;
          // 暂停推流
          // 服务器实现，客服端不再需要
          // this.record.ctrl({ device, channel, protocolType, ctrlcmd: 1 });
        } else if (end - currentTime < 5 && networkPaused) {
          // 缓冲区小于5秒数据，并且视频流服务没有推流，则继续推流
          // 开始推流
          // this.record.ctrl({ device, channel, protocolType, ctrlcmd: 0 });
          // item.networkPaused = false;
        }
      } else if (currentTime > 0) {
        item.video.currentTime = 0;
      }
      item.currentTime = item.video.currentTime;
      if (!item.splaytime && item.currentTime) {
        item.splaytime = Date.now();
      }
      if (!item.isDragging) {
        this.setProgress(item);
      }
    },
    setProgress(item) {
      if (!item.using) return;
      const { begintime, endtime, seekTime, currentTime } = item;
      if (!(currentTime > 0 && endtime >= seekTime && seekTime >= begintime))
        return "";

      const duration = endtime - begintime;
      const time = seekTime - begintime + currentTime;
      item.progress = Number(((time / duration) * 100).toFixed(2));
      item.progressText = `${this.timeFormat(~~time * 1000)}/${this.timeFormat(
        ~~duration * 1000
      )}`;
    }
  }
};
</script>
