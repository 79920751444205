
<script>

import PlayerMixin from './ByskPlayer';

import {
  delay,
} from '@/js/common';
import { uuid } from '@/utils/uuid';

export default {
  name: 'ByskPlayerReal',
  mixins: [PlayerMixin],
  // extends: PlayerMixin,
  data() {
    return {
      id: 'real'
    };
  },
  computed: {
    isReal() {
      return true;
    },
    real() {
      return this.byskFlv.real;
    }
  },
  beforeMount() {
  },
  mounted() {
  },
  beforeDestroy() {

  },
  errorCaptured(error, vm, info) {
    console.log(error, vm, info);
  },
  methods: {
    open(ids, params, cb, flvMds = {}, flvConfig = {}) {
      ids = ids instanceof Array ? [...ids] : [ids];
      const { device, channel, protocolType, codetype, plate, channelsName, vehicleId, groupId, codeTypeCtrlBtnEnabled, SpecialSign, datatype } = params;
      const channels = (channel + '').split(',').map(p => p * 1);
      this.players.forEach(p => {
        p.maximized = false;
        p.fullscreened = false;
      });
      let players = this.players.filter(p => ids.indexOf(p.videoId) > -1);
      this.timing();

      flvMds = this.createFlvMDS({ hasAudio: false, ...flvMds });

      let uid = uuid();
      players.forEach((player, i) => {

        this.endVideoRecord(player);

        player.uid = uid;
        player.device = device;
        player.channel = channels[i];
        player.protocolType = protocolType;
        player.plate = plate;
        player.channelsName = channelsName;
        player.groupId = groupId;
        player.vehicleId = vehicleId;
        player.using = true;
        player.paused = false;
        player.replayBtnHide = true;
        player.codeTypeCtrlBtnEnabled = codeTypeCtrlBtnEnabled;
        player.codetypeSwitching = false;
        player.currentTime = 0;
        player.downloadSpeed = 0;
        player.splaytime = 0;
        player.flvMds = flvMds;
        player.SpecialSign = SpecialSign; // 特殊协议标志 0:不处理;1:粤标
        player.datatype = datatype >= 0 ? datatype : 1;
        player.codetype = codetype >= 0 ? codetype : 1;
      });

      const start = (count) => {
        players = players.filter(p => p.using && p.uid === uid);// && p.currentTime < 1)
        if (!players.length) return;
        players.forEach(player => {
          player.logs.clear();
          player.logs.push(`正在请求视频${['...', '....', '.....'][count - 1]}`);
          player.currentTime = 0;
        });
        delay(() => {
          players = players.filter(p => p && p.using && p.uid === uid);
          if (!players.length) return;
          this.real.open(
            players.map(p => p.video),
            {
              device,
              channel: players.map(p => p.channel).join(','),
              protocolType,
              codetype,
              SpecialSign,
              datatype,
            }, res => {
              if ([-1, -2, -6, -7, -8, -102].includes(res.status) && count < 3) {
                //重连3次
                start(++count);
                return;
              }
              players = players.filter(p => p.using && p.uid === uid);
              players.forEach(player => player.logs.push(res.info));

              players.forEach(player => {
                if ([1, 2].indexOf(res.status) < 0) {
                  player.uid = '';
                  player.using = false;
                  player.paused = true;
                  if (player.video) player.video.muted = true;
                  player.replayBtnHide = false;
                  player.currentTime = 0;
                  player.downloadSpeed = 0;
                  player.codetypeSwitching = false;
                  player.splaytime = 0;
                  player.video = null;
                  plyer.SpecialSign = 0;
                } else if (res.status === 1) {
                  player.replayBtnHide = true;
                  player.logs.push('设备响应成功,视频加载中...');
                  //// player.logs.clear()
                } else if (res.status === 2) {
                  player.codetype = res.codetype;
                }
              });

              if (cb) cb(res);
            }, flvMds, flvConfig);
        }, count > 1 ? 2000 : 0);
      };

      start(1);
    },
    close(el, params, cb) {
      const { device, channel, protocolType, replayBtnHide = false, isEmpty } = params;
      let player = this.players.find(p => p.device === device && p.channel === channel && p.protocolType === protocolType && p.using);
      this.endVideoRecord(player);

      this.real.close(el, { device, channel, protocolType }, cb);
      if (player) {
        player.uid = '';
        player.paused = true;
        player.muted = true;
        player.using = false;
        player.replayBtnHide = replayBtnHide;
        player.splaytime = 0;
        player.downloadSpeed = 0;
        player.currentTime = 0;
        player.fullscreened = false;
        player.maximized = false;
        player.codetype = 1;
        player.codetypeSwitching = false;
        player.logs.clear();
        if (player.video) {
          player.video.removeAttribute('vid');
          if (player.video) player.video.muted = true;
          player.video = null;
        }
        if (isEmpty) {
          player.plate = "";
          player.channel = "";
        }
      }
    },
    openListen(el, params, cb, flvMds = {}, flvConfig = {}) {
      let { device, channel, protocolType, SpecialSign } = params;
      channel *= 1;

      el.muted = false;
      this.real.openListen(el, { device, channel, protocolType, SpecialSign }, cb, flvMds, flvConfig);
    },
    closeListen(params, cb) {
      let { device, channel, protocolType } = params;
      channel *= 1;

      this.real.closeListen({ device, channel, protocolType }, cb);
    },
    openSpeak(params, cb, flvMds = {}, flvConfig = {}) {
      let { device, protocolType, SpecialSign } = params;

      let player = this.players[this.players.length - 1];
      player.using = true;
      this.real.openSpeak(player.audio, { device, protocolType, SpecialSign }, res => {
        if ([1, 2].indexOf(res.status) < 0) {
          player.using = false;
          player.muted = true;
          player.audio = null;
        } else if (res.status === 2) {
          player.device = res.device;
          player.muted = false;
        }
        if (cb) cb(res);
      }, flvMds, flvConfig);
    },
    closeSpeak(params, cb) {
      const { device, protocolType } = params;

      let player = this.players[this.players.length - 1];
      player.using = false;
      player.muted = true;
      player.audio = null;
      this.real.closeSpeak({ device, protocolType }, cb);
    },
    onMessage(e) {
      if (!(e instanceof String)) {
        const { status } = e;

      }
    },
    switchCodetype(params, cb) {
      this.real.switchCodetype(params, cb);
    },
    onClickCtrlStop(item) {
      const { device, channel, protocolType, plate, vehicleId, groupId, using, stopCtrlBtnEnabled } = item;
      if (device && channel && using && stopCtrlBtnEnabled) {
        this.$emit('playend', { device, channel, protocolType, plate, vehicleId, groupId });
        this.close(null, { device, channel, protocolType }, res => { });
      }
    },
    onClickCtrlCodetype(item, codetype) {
      const { device, channel, protocolType, using, SpecialSign } = item;
      if (item.paused || !item.splaytime || item.codetype === codetype || !using) return;

      item.logs.clear();
      // this.players.filter(p => p.device === device
      //   && p.channel !== channel
      //   && p.codetype === 0
      //   && p.using)
      //   .forEach(p => {
      //     p.logs.clear();
      //     p.codetypeSwitching = true;
      //     p.logs.push(`正在切换标清,请稍后`);
      //     // p.paused = true;
      //     this.switchCodetype({ device, channel: p.channel, protocolType, codetype: 1, SpecialSign: p.SpecialSign }, res => { });
      //   });

      item.codetypeSwitching = true;
      item.logs.push(`正在切换${['高清', '标清'][codetype]},请稍后`);
      item.codetype = codetype;
      // item.paused = true;
      this.switchCodetype({ device, channel, protocolType, codetype, SpecialSign },
        res => { });
      item.codeTypeCtrlPaneHide = true;
    },
    onSwitchCodetypeCallback(e) {
      const { status, info, device, channel, codetype } = e;
      let player = this.players.find(p => p.device === device && p.channel === channel && p.using);
      player.logs.clear();
      if (player && player.codetype !== codetype) {
        player.codetype = codetype;
      }
      // player.paused = false;
      player.logs.push(info);
      player.codetypeSwitching = false;
      if (this.switchcodetypecb) this.switchcodetypecb(e);
    },
    onCtrlMuted(item) {
      delay(() => {
        const { device, channel, protocolType, SpecialSign, flvMds } = item;
        if (item.muted) {//静音
          //this.closeListen({ device, channel, protocolType })
          if (item.audio) item.audio.muted = true;
          if (item.video) item.video.muted = true;
        } else {
          //其他播放器静音
          this.players.slice(0, this.players.length - 1)
            .filter(p => p.id !== item.id && !p.muted)
            .forEach(p => {
              const { device, channel, protocolType } = p;
              p.muted = true;
              if (p.audio) p.audio.muted = true;
              if (p.video) p.video.muted = true;
              // this.closeListen({ device, channel, protocolType })
            });
          if (flvMds.hasAudio) {
            // 音频混合进视频中
            item.video.muted = item.muted;
          } else {
            // 音频和视频分开
            if (!item.paused && !item.audio.src) {
              this.openListen(item.audio, { device, channel, protocolType, SpecialSign }, res => {
                if (![1, 2].includes(res.status)) {
                  if (item.audio) item.audio.muted = item.muted;
                }
              });
            }
            if (item.audio) item.audio.muted = item.muted;
          }

        }
      }, 200);
    },
    handleReplay(item) {

      const { videoId, device, channel, protocolType, codetype, datatype, plate, vehicleId, groupId, codeTypeCtrlBtnEnabled, SpecialSign } = item;
      if (device && channel) {

        if (this.players.some(p => p.using && p.device === device && p.channel === channel)) {
          item.logs.clear();
          item.logs.push('该设备通道已在播放');
          return;
        }
        this.open(videoId, {
          device,
          channel,
          protocolType,
          codetype,
          plate,
          vehicleId,
          groupId,
          codeTypeCtrlBtnEnabled,
          SpecialSign,
          datatype,
        }, res => {
          if ([1, 2].indexOf(res.status) < 0) {
            this.$emit('playend', { device, channel, protocolType, plate, vehicleId, groupId });
          } else {
            if (res.status === 2) {
              this.$emit('playstart', { vid: videoId, device, channel, protocolType, plate, vehicleId, groupId });
            }
          }
        });
      }
    },
    onClickPlayer(item) {
      const { device, channel, protocolType, plate, vehicleId, groupId, using } = item;
      this.$emit('click', { device, channel, protocolType, plate, vehicleId, groupId, using });
    },
    videoEventHandler(e, item) {
      switch (e.type) {
        case 'canplay':
        case 'playing':
        case 'canplaythrough':
          if (!item.paused && document[this.getVisibilityProperty().hidden] && item.video) {
            item.video.play();
            this.changeVideoCurrentTime(item);
          }
          break;
        case 'timeupdate':
        case 'progress':
          if (!item.paused && item.video) {
            if (item.video.paused) item.video.play();
            this.changeVideoCurrentTime(item);
          }
          break;
      }
    },
    audioEventHandler(e, item) {
      switch (e.type) {
        case 'canplay':
        case 'playing':
        case 'canplaythrough':
          if (document[this.getVisibilityProperty().hidden] && item.audio && !item.muted) {
            item.audio.play();
          }
          break;
        case 'progress':
          if (item.audio && !item.muted) {
            item.audio.play();
          }
          break;
      }
    },
    changeVideoCurrentTime(item) {
      let { buffered, currentTime, playbackRate } = item.video;
      if (buffered.length > 0) {
        // 返回指定范围的结束时间。
        const end = buffered.end(0);
        const buffms = end - currentTime;
        if (end < currentTime) {
          item.video.currentTime = end - 0.1;
        } else if (buffms <= 0.5 && playbackRate !== 0.5) {
          item.video.playbackRate = 0.5;
        } else if (buffms > 0.5 && buffms <= 1.2 && playbackRate !== 0.7) {
          item.video.playbackRate = 0.7;
        } else if (buffms > 1.2 && buffms <= 3 && playbackRate !== 1) {
          item.video.playbackRate = 1;
        } else if (buffms > 3 && buffms <= 5 && playbackRate !== 1.5) {
          item.video.playbackRate = 1.5;
        } else if (buffms >= 5 && playbackRate !== 2) {
          item.video.playbackRate = 2;
        }
      } else if (currentTime > 0) {
        item.video.currentTime = 0;
      }
      item.currentTime = item.video.currentTime;
      if (!item.splaytime && item.currentTime) {
        item.splaytime = Date.now();
      }
    }
  }
}
</script>
