// import 'element-ui/lib/theme-chalk/index.css';
import Vue from 'vue';

import ByskFlv from './ByskFlv';
import ByskRequest from './ByskRequest';
import PlayerReal from './ByskPlayerReal';
import PlayerRecord from './ByskPlayerRecord';
import PlayerLocal from './ByskPlayerLocal';

console.log('byskplayerjs_ver', process.env._buildtime);

const createApp = ({ el, isReal, isRecord, isLocal }) => {
  const app = new Vue({
    el,
    components: {
      PlayerReal,
      PlayerRecord,
      PlayerLocal,
    },
    render: (h) => (
      <KeepAlive>
        {isReal && <PlayerReal ref="refReal"></PlayerReal>}
        {isRecord && <PlayerRecord ref="refRecord"></PlayerRecord>}
        {isLocal && <PlayerLocal ref="refLocal"></PlayerLocal>}
      </KeepAlive>),
  });
  return app;
};

class Player {
  constructor({
    el,
    id,
    isReal,
    isRecord,
    isSerRecord,
    isLocal,
    userId,
    userkey,
    userLevel,
    platform,
    baseURL,
    isSwitchCodetypeOnFullscreen,
    isPicInPic,
    open,
    close,
    click,
    playstart,
    startPlay,
    onVelocity,
    playend,
    register,
    message,
    heart,
    switchcodetype,
    lookup,
    snapshot,
    showParameter
  }) {
    this._version = '2.0.2';
    this._isReal = isReal;
    this._isRecord = isRecord;
    this._isSerRecord = isSerRecord;
    this._isLocal = isLocal;

    this._el = id ? document.getElementById(id) : el;
    if (this._el) {
      this._app = createApp({
        el: this._el,
        isReal,
        isRecord,
        isSerRecord,
        isLocal
      });


      this.on('open', open);
      this.on('close', close);
      this.on('click', click);
      this.on('playstart', playstart);
      this.on('startPlay', startPlay);
      this.on('onVelocity', onVelocity);
      this.on('playend', playend);
      this.on('register', register);
      this.on('message', message);
      this.on('heart', heart);
      this.on('switchcodetype', switchcodetype);
      this.on('lookup', lookup);
      this.on('snapshot', snapshot);


      platform = platform || '';
      if (process.env.openApi === 'open') {
        //开发平台
        platform = 'openApi';
        userLevel = 1;
      }

      this.initPlayer({
        id,
        userId,
        userkey,
        userLevel,
        platform,
        baseURL,
        isSwitchCodetypeOnFullscreen,
        isPicInPic,
        showParameter
      });
    }
  }
  get version() {
    return this._version;
  }
  get player() {
    if (!this._app) return null;
    const refRoot = this._app.$refs;
    if (this._isReal) return refRoot.refReal;
    if (this._isRecord) return refRoot.refRecord;
    if (this._isLocal) return refRoot.refLocal;
  }
  set poster(url) {
    this.player.setPoster(url);
  }
  set autoCloseTime(time) {
    this.player.setAutoCloseTime(time);
  }
  get real() {
    return {
      open: this.player.open,
      close: this.player.close,
      openListen: this.player.openListen,
      closeListen: this.player.closeListen,
      openSpeak: this.player.openSpeak,
      closeSpeak: this.player.closeSpeak,
      switchCodetype: this.player.switchCodetype
    };
  }
  get record() {
    return {
      query: this.player.query,
      open: this.player.open,
      close: this.player.close,
      forward: this.player.forward,
      backward: this.player.backward,
      keyframe: this.player.keyframe,
    };
  }
  get ftp() {
    return this.player.byskFlv.ftp;
  }

  get gaDownload() {
    return this.player.byskFlv.gaDownload;
  }

  get local() {
    return {
      diskQuery: this.player.diskQuery,
      query: this.player.query,
      open: this.player.open,
      close: this.player.close,
      play: this.player.play,
      pause: this.player.pause,
      forward: this.player.forward,
      backward: this.player.backward,
      keyframe: this.player.keyframe,
      exportFile: this.player.exportFile,
      closeExport: this.player.closeExport,
      queryExportState: this.player.queryExportState,
    };
  }
  on(eventName, fn) {
    this.player.$on(eventName, fn || (res => { }));
  }
  emit(eventName, ...options) {
    this.player.$emit(eventName, ...options);
  }
  setPlayerNum(num, maxNum = 0) {
    this.player.setPlayerNum(num, maxNum);
  }
  setLayout(options) {
    this.player.setLayout(options);
  }
  allocate(num, start) {
    return this.player.allocate(num, start);
  }
  initPlayer(config) {
    this.player.init(config);
  }
  destroy(flag = true, options = {}) {
    this.player.destroy(flag, options);
  }
  lookUp(options) {
    this.player.lookUp(options);
  }
  sendBuffer(buffer) {
    this.player.sendBuffer(buffer);
  }
}

window.byskplayer = Player;
window.ByskFlv = ByskFlv;
window.ByskRequest = ByskRequest;

export default Player;
export {
  ByskFlv,
  ByskRequest,
};