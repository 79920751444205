import ByskRequest from "./ByskRequest";
import ByskRecorder from "./ByskRecorder";
import "@/libs/flv";

import { uuid } from "@/utils/uuid";

import { sleep } from "@/utils/sleep";

// video/mp4;codecs=avc1.4d000a
// audio/mp4;codecs=mp4a.40.2

class ByskFlv {
  constructor(config) {
    console.log("flv: " + flvjs.version);

    const { id, baseURL, username, userkey, userLevel, platform } = config;
    // 存储事件
    this._events = {};
    // 注册是校验key, 登录获取
    this._userkey = userkey;
    // 用户等级, 默认1
    this._userLevel = userLevel;
    this._platform = platform;

    this._players = []; // type:  0：实时，2：回放，3：对讲，4：本地回放，5：监听
    // 创建一条视频服务websocket
    this._request = new ByskRequest({
      id,
      baseURL, // 视频服务地址
      username, // 登录用户名
      userkey, // key
      userLevel, // 用户级别
      platform,
      open: this._onRequestOpen.bind(this),
      close: this._onRequestClose.bind(this),
      register: this._onRequestRegister.bind(this),
      message: this._onRequestMessage.bind(this),
      heart: this._onHeartcheck.bind(this),
    });

    // 创建录音器实例
    this._recorder = new ByskRecorder();
  }

  /**
   * 注册事件
   */
  on(eventName, cb) {
    if (!this._events[eventName]) this._events[eventName] = [];
    this._events[eventName].push(cb);
  }

  /**
   * 注销事件
   */
  off(eventName) {
    this._events[eventName] = [];
    delete this._events[eventName];
  }

  /**
   * 触发事件
   */
  emit(eventName, ...options) {
    (this._events[eventName] || []).forEach((cb) => cb.call(this, ...options));
  }

  /**
   * 消耗链路
   */
  destroy() {
    this._events = {};
    this.destroyAllFlv();
    if (this._request) {
      this._request.destroy();
    }
  }

  /**
   * 消费所有的flvjs实例
   */
  destroyAllFlv() {
    let flvplayers = this._players.map((p) => p.flvplayer);
    flvplayers.forEach((p) => this._flvDestroy(p));
    flvplayers = null;
    this._players = [];
  }

  /**
   * 向服务器发送消息
   */
  sendBuffer(buffer) {
    if (!this._request) return;
    this._request.sendBuffer(buffer);
  }

  /**
   * 回应查岗
   */
  lookUp(params, cb) {
    let {
      username,
      info,
      OBJECT_TYPE,
      OBJECT_ID,
      INFO_ID,
      session,
      clientType,
      token,
    } = params;
    this._request.cmdLookUp(
      {
        username,
        info,
        OBJECT_TYPE,
        OBJECT_ID,
        INFO_ID,
        session,
        clientType,
        token,
      },
      cb
    );
  }

  /**
   * 通过flvjs播放
   */
  flvStart(element, mds, config = {}) {
    return this._flvStart(element, mds, config);
  }

  /**
   * 销毁flvjs实例
   */
  flvDestroy(flvplayer) {
    return this._flvDestroy(flvplayer);
  }

  /**
   * 获取播放列表
   */
  get playerList() {
    return this._players;
  }

  /**
   * 实时视频对象
   */
  get real() {
    return {
      /**
       * 打开实时视频
       */
      open: this._realOpen.bind(this),
      /**
       * 关闭实时视频
       */
      close: this._realClose.bind(this),
      /**
       * 打开监听
       */
      openListen: this._realOpenListen.bind(this),
      /**
       * 关闭监听
       */
      closeListen: this._realCloseListen.bind(this),
      /**
       * 开启对讲
       */
      openSpeak: this._realOpenSpeak.bind(this),
      /**
       * 关闭对讲
       */
      closeSpeak: this._realCloseSpeak.bind(this),
      /**
       * 切换码流(高清,标清)
       */
      switchCodetype: this._realSwitchCodetype.bind(this),
    };
  }

  /**
   * 回放视频对象
   */
  get record() {
    return {
      /**
       * 查询录像记录
       */
      query: this._recordQuery.bind(this),
      /**
       * 播放回放视频
       */
      open: this._recordOpen.bind(this),
      /**
       * 关闭回放视频
       */
      close: this._recordClose.bind(this),
      /**
       * 继续播放视频
       */
      play: this._recordPlay.bind(this),
      /**
       * 暂停播放视频
       */
      pause: this._recordPause.bind(this),
      /**
       * 倍数播放
       */
      forward: this._recordForward.bind(this),
      /**
       * 关键帧快退播放
       */
      backward: this._recordBackward.bind(this),
      /**
       * 关键帧快进播放
       */
      keyframe: this._recordKeyFrame.bind(this),
      /**
       * 拖动播放
       */
      seekpos: this._recordSeekPos.bind(this),
      /**
       * 回放控制指令
       */
      ctrl: this._recordCtrl.bind(this),
    };
  }

  /**
   * 本地回放视频对象
   */
  get local() {
    return {
      diskQuery: this._localDiskQuery.bind(this),
      query: this._localQuery.bind(this),
      open: this._localOpen.bind(this),
      close: this._localClose.bind(this),
      play: this._localPlay.bind(this),
      pause: this._localPause.bind(this),
      forward: this._localForward.bind(this),
      backward: this._localBackward.bind(this),
      keyframe: this._localKeyFrame.bind(this),
      ctrl: this._localCtrl.bind(this),
      exportFile: this._localExportFile.bind(this),
      closeExport: this._localCloseExport.bind(this),
      queryExportState: this._localQueryExportState.bind(this),
    };
  }

  /**
   * ftp指令对象
   */
  get ftp() {
    return {
      downloadToServer: this._ftpDownloadToServer.bind(this),
      downloadFile: this._jtDownload.bind(this),
      pause: this._ftpPause.bind(this),
      continue: this._ftpContinue.bind(this),
      cancel: this._ftpCancel.bind(this),
    };
  }

  get gaDownload() {
    return {
      downloadFile: this._gaDownload.bind(this),
      close: this._gaDownloadCtrl.bind(this),
    };
  }

  //#region real
  /**
   * 打开实时视频
   * @param els - video标签, 多个使用数组
   * @param cb - 视频服务响应结果回调. 先返回status=2获取到地址, 在返回status=1获取下发状态
   * @param flvMds - flvjs参数
   * @param flvConfig - flvjs配置参数
   * @param params.device - 终端号
   * @param params.channel - 通道号   多个使用逗号组成的字符串, 与els下标对应
   * @param params.protocolType - 设备协议类型 0: GF-1078, GB-1078; 1: GA系列
   * @param params.datatype - 数据类型
   * @param params.codetype - 码流类型
   * @param params.SpecialSign - 特殊协议   0: 不处理; 1: 粤标
   */
  _realOpen(els, params, cb, flvMds = {}, flvConfig = {}) {
    // 兼容单个element
    let elements = els instanceof Array ? [...els] : [els];
    let { device, channel, protocolType, datatype, codetype, SpecialSign } =
      params;

    // 解析通道号, 兼容逗号分隔组成的字符串格式
    const channels = ("" + channel)
      .trim()
      .split(",")
      .map((p) => p * 1)
      .filter((p) => p);

    // type: 0; 实时视频
    const type = 0;
    // 设备协议类型, 默认1,   1: GF-1078, GB-1078; 2: GA
    protocolType = ~~protocolType || 1;
    // codetype = codetype ?? 1;
    // 码流类型   0: 主码流; 1: 子码流, 默认1
    codetype = codetype >= 0 ? ~~codetype : 1;
    // 数据类型   0: 音视频; 1: 视频; 2: 双向对讲; 3: 监听; 4: 中心广播; 5: 透传, 默认1
    datatype = datatype >= 0 ? ~~datatype : 1;
    // 特殊协议   0: 不处理; 1: 粤标
    SpecialSign = SpecialSign || 0;

    // 为每个视频element生成uuid, 且与通道哈对应
    elements = elements.map((el, i) => {
      const uid = uuid();
      const channel = channels[i];
      if (el) el.setAttribute("uid", uid);
      return { el, channel, uid };
    });

    // 发起播放视频请求
    this._request.realMediaReq(
      {
        device,
        channel: channels.join(","), // 多个通道号用逗号分隔, 一般单通道号
        protocolType,
        datatype,
        codetype,
        SpecialSign,
      },
      (res) => {
        const { status, info, codetype, media_address } = res;
        if (status === 2) {
          // 视频服务端响应地址

          // 通过对比uuid, 比较element是否能够对应
          if (
            elements.every(
              ({ el, uid }) => !el || el.getAttribute("uid") !== uid
            )
          )
            return;

          const { video_address, addresses } = media_address;
          elements.forEach(({ el, channel, uid }) => {
            // 比较uuid
            if (!(el && el.getAttribute("uid") === uid)) return;

            let url = video_address;
            let obj; // 兼容多通道播放, 多通道播放时, addresses存储每个通道的播放地址
            if (!url && (obj = addresses.find((p) => p.channel == channel))) {
              url = obj.video_address;
            }

            // 检测该设备,通道号是否正在播放, type=0 实时视频
            let player = this._players.find(
              (p) =>
                p.device === device && p.channel === channel && p.type === type
            );

            if (player) {
              // 如果正在播放, 则停止播放
              this._removePlayer({ device, channel, type });
            }

            // 通过flvjs播放视频
            let flvplayer = this._flvStart(
              el,
              { hasAudio: false, hasVideo: true, ...flvMds, url },
              { ...flvConfig }
            );
            // 将改播放的设备通道, 及其他相关参数存储起来
            this._players.push({
              device,
              channel,
              codetype,
              datatype,
              type,
              flvplayer,
              element: el,
              flvMds: { ...flvMds },
              flvConfig: { ...flvConfig },
            });
          });
        } else if (status !== 1) {
          // 视频服务返回结果不是1或2; 则表明请求结束,请求失败
          // && elements.every(({ el }) => el && el.currentTime < 1)
          elements.forEach(({ el, channel, uid }) => {
            // 对比uuid, 判断element是否正确
            if (!(el && el.getAttribute("uid") === uid)) return;

            // 下发关闭指令
            this._realClose(el, {
              device,
              channel,
              protocolType,
            });
          });
        }

        // 回调, 返回给调用方
        if (cb) {
          cb({
            status, // 返回结果
            info, // 结果描述
            device, // 终端号
            channel, // 通道号
            protocolType, // 设备协议类型
            codetype, // 码流类型
            media_address, // 视频地址
          });
        }
        if (status !== 2) {
          elements = null;
          flvMds = null;
          flvConfig = null;
          cb = null;
        }
      }
    );
  }

  /**
   * 关闭实时视频指令
   * @param params.device - 终端号
   * @param params.channel - 通道号
   * @param params.protocolType - 协议类型
   * @param params.ctrlcmd - 指令类型   这里默认0, 关闭音视频, 可选
   * @param params.datatype - 数据类型  这里默认0, 关闭音视频, 可选
   * @param params.codetype - 码流类型  默认1, 子码流, 可选
   */
  _realClose(el, params, cb) {
    let element = el || {};
    let { device, channel, protocolType, ctrlcmd, datatype, codetype } = params;

    const type = 0; // 实时
    // 通道号
    channel *= 1;
    // 设备协议类型 1: GF-1078, GB-1078; 2: GA系列
    protocolType = ~~protocolType || 1;
    // 控制指令类型 0: 关闭音视频, 默认0
    ctrlcmd = ~~ctrlcmd;
    // 数据类型   0: 关闭音视频, 默认0
    datatype = ~~datatype;
    // 码流类型 1: 子码流, 默认1
    codetype = codetype >= 0 ? ~~codetype : 1;

    // type=5: 监听, 如果有监听则也需要关闭
    this._removePlayer({ device, channel, type: 5 }); // 监听
    // 删除flvjs实例
    this._removePlayer({ device, channel, type });

    // 下发关闭指令
    this._request.realMediaCtrl(
      {
        device,
        channel,
        protocolType,
        ctrlcmd,
        datatype,
        codetype,
      },
      (res) => {
        const { status, info } = res;
        if (cb) {
          cb({ status, info, device, channel });
          element = null;
          cb = null;
        }
      }
    );
  }

  /**
   * 打开监听
   * @param el - 音频播放的audio标签
   * @param cb - 视频服务返回结果回调
   * @param flvMds - flvjs参数
   * @param flvConfig - flvjs配置参数
   * @param params.device - 终端号
   * @param params.protocolType - 设备协议类型
   *
   */
  _realOpenListen(el, params, cb, flvMds = {}, flvConfig = {}) {
    let element = el;
    let { device, channel, protocolType, datatype, codetype, SpecialSign } =
      params;
    // 监听
    const type = 5;
    // 监听通道号默认33
    channel = ~~channel || 33;
    // 设备协议类型   1: GF-1078, GB-178; 2: GA系列
    protocolType = ~~protocolType || 1;
    // datatype = datatype ?? 3
    // 数据类型 默认3, 监听
    datatype = datatype >= 0 ? ~~datatype : 3;
    // 码流类型 默认1, 子码流
    codetype = codetype >= 0 ? ~~codetype : 1;
    // 特殊协议 0:不处理;1:粤标
    SpecialSign = SpecialSign || 0;

    this._request.realMediaReq(
      {
        device,
        channel,
        protocolType,
        datatype,
        codetype,
        SpecialSign,
      },
      (res) => {
        const { status, info, codetype, media_address } = res;

        if (status === 2) {
          const { audio_address } = media_address;
          const url = audio_address;
          // 通过flvjs播放
          let flvplayer = this._flvStart(
            element,
            { hasAudio: true, hasVideo: false, ...flvMds, url },
            { enableWorker: false, ...flvConfig }
          );
          // 不要静音
          flvplayer.muted = false;

          // 判断是否已经在监听
          let player = this._players.find(
            (p) =>
              p.device === device && p.channel === channel && p.type === type
          );
          if (player) {
            this._removePlayer({ device, channel, type });
          }

          // 存储播放设备通道类型
          this._players.push({
            device,
            channel,
            codetype,
            datatype,
            type,
            flvplayer,
            element,
            flvMds: { ...flvMds },
            flvConfig: { ...flvConfig },
          });
        } else if (status !== 1) {
          // 请求失败, 关闭监听
          this._realCloseListen({ device, channel, protocolType });
        }

        if (cb) {
          cb({
            status,
            info,
            device,
            channel,
            protocolType,
            codetype,
            media_address,
          });
        }
        if (status !== 2) {
          element = null;
          flvMds = null;
          flvConfig = null;
          cb = null;
        }
      }
    );
  }

  /**
   * 关闭监听
   * @param params.device - 终端号
   * @param params.protocolType - 设备协议类型
   */
  _realCloseListen(params, cb) {
    let { device, channel, protocolType, ctrlcmd, datatype, codetype } = params;
    // 监听
    const type = 5;
    // 监听的通道号默认33
    channel = ~~channel || 33;
    // 设备协议类型
    protocolType = ~~protocolType || 1;

    // 关闭已经的flvjs
    this._removePlayer({ device, channel, type });

    // 下发关闭监听指令
    this._request.realMediaCtrl(
      {
        device,
        channel,
        protocolType,
        ctrlcmd: ~~ctrlcmd,
        datatype: datatype >= 0 ? ~~datatype : 1,
        codetype: codetype >= 0 ? ~~codetype : 1,
      },
      (res) => {
        const { status, info } = res;
        if (cb) {
          cb({ status, info, device, channel });
          cb = null;
        }
      }
    );
  }

  /**
   * 打开对讲
   * @param el 音频audio标签
   * @param cb 返回结果回调
   * @param params.device - 终端号
   * @param params.protocolType - 设备协议类型
   */
  _realOpenSpeak(el, params, cb, flvMds, flvConfig) {
    let element = el;
    let { device, channel, protocolType, datatype, codetype, SpecialSign } =
      params;
    // 对讲
    const type = 3;
    // 设备协议类型
    protocolType = ~~protocolType || 1;
    // 对讲通道号, GF-1078,GB-1078默认36, GA系列默认1
    channel = ~~channel || [36, 1][protocolType - 1]; //
    // 数据类型 默认2
    datatype = datatype >= 0 ? ~~datatype : 2;
    // 码流类型 默认1
    codetype = codetype >= 0 ? ~~codetype : 1;
    // 特殊协议 0:不处理;1:粤标
    SpecialSign = SpecialSign || 0;

    let flvplayer;
    this._request.realMediaReq(
      {
        device,
        channel,
        protocolType,
        datatype,
        codetype,
        SpecialSign,
      },
      (res) => {
        const { status, info, codetype, media_address } = res;

        if (status === 1) {
          // 服务端响应成功, 开始采集声音

          this._recorder.start((evt) => {
            if (evt.status === 2) {
              // 采集到的声音, 通过flvjs发送到视频服务
              if (flvplayer) flvplayer.send(evt.buffer);
              return;
            }

            // 申请采集声音失败
            if (evt.status !== 1) {
              this._realCloseSpeak({ device, channel, protocolType });
            }
            if (cb) {
              cb({ ...evt, device, channel, protocolType });
            }
          });
          return;
        } else if (status === 2) {
          const { audio_address } = media_address;
          const url = audio_address;

          // 判断是否已经再播放
          let player = this._players.find(
            (p) =>
              p.device === device && p.channel === channel && p.type === type
          );
          if (player) {
            this._removePlayer({ device, channel, type });
          }

          // 播放
          flvplayer = this._flvStart(
            element,
            { hasAudio: true, hasVideo: false, ...flvMds, url },
            { enableWorker: false, ...flvConfig }
          );
          flvplayer.muted = false;

          // 存储
          this._players.push({
            device,
            channel,
            datatype,
            codetype,
            type,
            flvplayer,
            element,
            flvMds: { ...flvMds },
            flvConfig: { ...flvConfig },
          });
        } else {
          this._realCloseSpeak({ device, channel, protocolType });
        }

        if (cb) {
          cb({
            status,
            info,
            device,
            channel,
            protocolType,
            codetype,
            media_address,
          });
        }
      }
    );
  }

  /**
   * 关闭对讲
   * @param params.device - 终端号
   * @param params.protocolType - 设备协议类型
   */
  _realCloseSpeak(params, cb) {
    let { device, channel, protocolType, ctrlcmd, datatype, codetype } = params;

    // 协议类型 1: GF-1078, GB-1078; 2: GA系列
    protocolType = ~~protocolType || 1;
    // 对讲默认通道号   GF-1078,GB-1078 默认通道号36, GA系列默认通道号1
    channel = ~~channel || [36, 1][protocolType - 1];

    const type = 3; // 对讲

    // 判断是否正在对讲
    if (
      this._players.some(
        (p) => p.device === device && p.channel === channel && p.type === type
      )
    ) {
      // 停止采集声音
      this._recorder.close();
      // 删除flvjs
      this._removePlayer({ device, channel, type });
      // 发送指令
      this._request.realMediaCtrl(
        {
          device,
          channel,
          protocolType,
          ctrlcmd: ctrlcmd >= 0 ? ~~ctrlcmd : 4,
          datatype: datatype >= 0 ? ~~datatype : 1,
          codetype: codetype >= 0 ? ~~codetype : 1,
        },
        (res) => {
          const { status, info } = res;
          if (cb) {
            cb({ status, info, device, channel });
            cb = null;
          }
        }
      );
    }
  }

  /**
   * 高标清切换(切换码流)
   * @param params.device - 终端号
   * @param params.channel - 通道号
   * @param params.protocolType - 设备协议类型
   * @param params.SpecialSign - 特殊协议
   */
  _realSwitchCodetype(params, cb) {
    let {
      device,
      channel,
      protocolType,
      ctrlcmd,
      datatype,
      codetype,
      SpecialSign,
    } = params;
    const type = 0;
    // 设备协议类型 0: GF-1078, GB-0178; 2: GA系列
    protocolType = ~~protocolType || 1;
    // 通道号
    channel *= 1;
    // 特殊协议 0:不处理; 1:粤标
    SpecialSign = SpecialSign || 0;

    // 发送指令
    this._request.realMediaCtrl(
      {
        device,
        channel,
        protocolType,
        ctrlcmd: ctrlcmd >= 0 ? ~~ctrlcmd : 1,
        datatype: ~~datatype,
        codetype,
        SpecialSign,
      },
      (res) => {
        const { status, info } = res;
        if (cb) {
          cb({ status, info, device, channel });
          cb = null;
        }
      }
    );
  }
  //#endregion

  //#region record

  /**
   * 查询历史文件
   * @param params.device - 终端号
   * @param params.channel - 通道号
   * @param params.protocolType - 设备协议类型
   * @param params.begintime - 开始时间(utc, 秒)
   * @param params.endtime - 结束时间(utc, 秒)
   */
  _recordQuery(params, cb) {
    let { device, channel, protocolType, begintime, endtime } = params;
    protocolType = ~~protocolType || 1;
    channel *= 1;

    if (protocolType == 2) {
      // GA 设备流程

      // 存储总文件
      let recordfiles = [];
      this._recordDevQueryGA(params, (data) => {
        let { status, info, curPage, totalPage, listFile } = data;
        if (status === 1) {
          // 查询未结束, 合并返回的文件
          recordfiles = recordfiles.concat(listFile || []);
        }
        // 查询结束
        if (cb && !(status == 1 && curPage < totalPage)) {
          // 处理排序
          recordfiles = recordfiles
            .sort((a, b) => a.begintime - b.begintime)
            .map((p) => ({
              ...p,
              begintime: Math.max(p.begintime, begintime * 1000),
              endtime: Math.min(p.endtime, endtime * 1000),
            }));
          let records = recordfiles.length;
          cb({
            status,
            info,
            records,
            recordfiles,
            curPage,
            totalPage,
            device,
            channel,
            protocolType,
          });
        }
      });
    } else if (protocolType === 1) {
      // GF,GB设备流程
      this._recordDevQuery1078(params, cb);
    }
  }

  /**
   * 查询GF,GB历史文件
   * @param params.device - 终端号
   * @param params.channel - 通道号
   * @param params.protocolType - 设备协议类型
   * @param params.begintime - 开始时间(utc, 秒)
   * @param params.endtime - 结束时间(utc, 秒)
   * @param params.alarmSign - 报警类型   0: 无报警
   * @param params.datatype - 数据类型
   * @param params.codetype - 码流类型
   * @param params.storgetype - 存储类型
   */
  _recordDevQuery1078(params, cb) {
    //1078设备查询
    let {
      device,
      channel,
      begintime,
      endtime,
      alarmSign,
      protocolType,
      datatype,
      codetype,
      storgetype,
    } = params;
    protocolType = ~~protocolType || 1;
    // 数据类型    0: 音视频; 1: 音频; 2: 视频; 3: 音频或视频
    datatype = ~~datatype;
    // 码流类型   0: 所有码流; 1: 主码流; 2: 子码流
    codetype = codetype >= 0 ? ~~codetype : 0;
    // 存储类型
    storgetype = ~~storgetype;
    channel *= 1;

    // 发送指令
    this._request.devRecordQuery(
      {
        device,
        channel,
        begintime,
        endtime,
        alarmSign,
        protocolType,
        datatype,
        codetype,
        storgetype,
      },
      (res) => {
        let { status, info, listFile } = res;
        if (cb) {
          let recordfiles = [];
          if (status === 1) {
            // 文件处理排序
            recordfiles = (listFile || [])
              .sort((a, b) => a.begintime - b.begintime)
              .map((p) => ({
                ...p,
                begintime: Math.max(p.begintime, begintime * 1000),
                endtime: Math.min(p.endtime, endtime * 1000),
              }));
          }

          let records = recordfiles.length;
          cb({
            status,
            info,
            records,
            recordfiles,
            device,
            channel,
            protocolType,
          });
          cb = null;
        }
      }
    );
  }

  /**
   * GA系列查询历史文件
   * @param params.device - 终端号
   * @param params.channel - 通道号
   * @param params.begintime - 开始时间(utc, 秒)
   * @param params.endtime - 结束时间(utc, 秒)
   * @param params.alarmSign - 报警类型   0: 无报警
   * @param params.protocolType - 设备协议类型
   * @param params.page - 页码    0: 第一页, 2: 第二页, ...
   * @param params.datatype - 数据类型
   * @param params.codetype - 码流类型
   * @param params.storgetype - 存储类型
   */
  _recordDevQueryGA(params, cb) {
    //GA设备查询
    let {
      device,
      channel,
      begintime,
      endtime,
      alarmSign,
      protocolType,
      page,
      datatype,
      codetype,
      storgetype,
    } = params;
    protocolType = ~~protocolType || 1;
    // 0: 音视频; 1: 音频; 2: 视频; 3: 音频或视频
    datatype = ~~datatype;
    // 0: 所有码流; 1: 主码流; 2: 子码流
    codetype = codetype >= 0 ? ~~codetype : 1;
    storgetype = ~~storgetype;
    page = ~~page;
    protocolType = ~~protocolType || 2;
    channel *= 1;

    let param = {
      device,
      channel,
      begintime,
      endtime,
      alarmSign,
      protocolType,
      page,
      datatype,
      codetype,
      storgetype,
    };

    // 发送指令
    this._request.devRecordQuery(param, (res) => {
      const { status, info, curPage, totalPage, listFile } = res;
      if (status === 1 && curPage < totalPage) {
        // 未查询完成, 递归查询下一页数据
        this._recordDevQueryGA({ ...param, page: curPage + 1 }, cb);
      }
      if (cb) {
        cb({ status, info, curPage, totalPage, listFile });
      }
    });
  }

  /**
   * 开始播放历史视频
   * @param els - 视频video标签, 兼容多个
   * @param params.device - 终端号
   * @param params.channel - 通道号, 多个用英文逗号分隔, 与els对应
   * @param params.begintime - 开始时间(utc, 秒)
   * @param params.endtime - 结束时间(utc, 秒)
   * @param params.protocolType - 设备协议类型
   * @param params.storgetype - 存储类型
   * @param params.fileName - 文件名称
   * @param params.playway - 回放方式    0: 正常回放; 1: 快进回放; 2: 关键帧快退回放; 3: 关键帧播放; 4: 单帧播放; 5: bsj扩展回放方式, 设备放回GPS数据
   * @param params.playspeed - 播放倍数(playway为1或2时有效)   0: 无效; 1: 1倍; 2: 2倍; 3: 4倍; 4: 8倍; 5: 16倍
   * @param params.datatype - 数据类型
   * @param params.codetype - 码流类型
   * @param params.SpecialSign - 特殊协议
   */
  _recordOpen(els, params, cb, flvMds = {}, flvConfig = {}) {
    let elements = els instanceof Array ? [...els] : [els];

    let {
      device,
      channel,
      begintime,
      endtime,
      fileName,
      protocolType,
      storgetype,
      playway,
      playspeed,
      datatype,
      codetype,
      SpecialSign,
      seekpos,
      ctrlcmd,
    } = params;
    if (ctrlcmd && ctrlcmd === 5) {
      if (!playspeed) {
        playspeed = 0;
      }
      this._request.devRecCtrl(
        {
          device,
          channel,
          protocolType,
          ctrlcmd,
          playspeed,
          seekpos,
        },
        (res) => {
          if (cb) cb(res);
        }
      );
      return false;
    }
    // 类型: 回放
    const type = 2;
    protocolType = ~~protocolType || 1;
    // 0：音视频; 1：音频; 2：视频; 3：音频或视频; 4：音视频和定位数据一起上传
    datatype = datatype >= 0 ? ~~datatype : 4;
    // 0：子码流或主码流; 1：主码流; 2：子码流; 如果此通道只传输音频，此字段置0
    codetype = codetype >= 0 ? ~~codetype : 1;
    storgetype = ~~storgetype;
    playway = ~~playway;
    playspeed = ~~playspeed;
    channel *= 1;
    // 特殊协议标志 0:不处理;1:粤标
    SpecialSign = SpecialSign || 0;

    let param = {
      device,
      channel,
      begintime,
      endtime,
      protocolType,
      playway,
      playspeed,
      storgetype,
      datatype,
      codetype,
      SpecialSign,
      seekpos,
      ctrlcmd,
    };
    // 映射文件名称
    if (fileName) param.startoff = fileName;

    // 处理通道号, 个每个video生成uuid
    const channels = ("" + channel)
      .trim()
      .split(",")
      .map((p) => p * 1);
    elements = elements.map((el, i) => {
      const uid = uuid();
      const channel = channels[i];
      el.setAttribute("uid", uid);
      return { el, channel, uid };
    });

    this._request.devRecReq(param, (res) => {
      if (elements.some(({ el, uid }) => el && el.getAttribute("uid") !== uid))
        return;
      const { status, info, media_address } = res;

      if (status === 2) {
        const { video_address, addresses } = media_address;
        elements.forEach(({ el, channel, uid }) => {
          let url = video_address;
          let obj;
          if (!url && (obj = addresses.find((p) => p.channel == channel))) {
            url = obj.video_address;
          }

          let player = this._players.find(
            (p) =>
              p.device === device && p.channel === channel && p.type === type
          );
          if (player) {
            this._removePlayer({ device, channel, type });
          }
          flvMds = { hasAudio: true, hasVideo: true, ...flvMds };
          if (!flvMds.hasAudio) {
            //不播放音频，将音视频改为视频
            url = url.replace(".media", ".video");
          }

          let flvplayer = this._flvStart(
            el,
            { ...flvMds, url },
            { ...flvConfig }
          );
          this._players.push({
            device,
            channel,
            codetype,
            datatype,
            type,
            flvplayer,
            element: el,
            flvMds: { ...flvMds },
            flvConfig: { ...flvConfig },
          });
        });
      } else if (status !== 1) {
        elements.forEach(({ el, channel, uid }) => {
          if (!(el && el.getAttribute("uid") === uid)) return;
          this._recordClose(el, {
            device,
            channel: channel,
            protocolType,
          });
        });
      }

      if (cb) {
        cb({
          status,
          info,
          device,
          channel,
          fileName,
          begintime,
          endtime,
          protocolType,
          codetype,
          media_address,
        });
      }
      if (status !== 2) {
        elements = null;
        flvMds = null;
        flvConfig = null;
        cb = null;
      }
    });
  }

  /**
   * 关闭回放视频播放
   */
  _recordClose(el, params, cb) {
    let element = el || {};
    let { device, channel, protocolType, ctrlcmd, playspeed, seekpos, forced } =
      params;
    forced = forced !== undefined ? forced : true; //是否强制清除flv player, 过检拖动播放时,不用强制清除flv player
    const type = 2; // 回放
    protocolType = ~~protocolType || 1;
    ctrlcmd = ctrlcmd >= 0 ? ~~ctrlcmd : 2; // 结束回放
    playspeed = ~~playspeed;
    seekpos = ~~seekpos;
    channel *= 1;

    if (
      this._players.some(
        (p) => p.device === device && p.channel === channel && p.type === type
      )
    ) {
      forced && this._removePlayer({ device, channel, type });
      this._recordCtrl(
        { device, channel, protocolType, ctrlcmd, playspeed, seekpos },
        cb
      );
    } else if (cb) {
      cb({ device, channel, protocolType });
    }
  }

  _recordTrack(params, cb) {
    console.log(params);
    let {
      device,
      channel,
      begintime,
      endtime,
      protocolType,
      storgetype,
      playway,
      playspeed,
      datatype,
      codetype,
    } = params;
    protocolType = ~~protocolType || 1;
    datatype = datatype >= 0 ? ~~datatype : 4;
    codetype = codetype >= 0 ? ~~codetype : 1;
    storgetype = ~~storgetype;
    playway = ~~playway;
    playspeed = ~~playspeed;
    channel *= 1;

    this._devRecReq(
      {
        device,
        channel,
        begintime,
        endtime,
        protocolType,
        playway,
        playspeed,
        storgetype,
        datatype,
        codetype,
      },
      (res) => {
        const { status, info } = res;
        if (cb) {
          cb({ status, info });
        }
      }
    );
  }

  // 开始播放
  _recordPlay(params, cb) {
    let { device, channel, protocolType, ctrlcmd, playspeed, seekpos } = params;
    const type = 2; // 回放
    protocolType = ~~protocolType || 1;
    ctrlcmd = ~~ctrlcmd; // 0: 开始回放
    playspeed = ~~playspeed;
    seekpos = ~~seekpos;
    channel *= 1;

    this._recordCtrl(
      { device, channel, protocolType, ctrlcmd, playspeed, seekpos },
      cb
    );

    let player = this._players.find(
      (p) => p.device === device && p.channel === channel && p.type === type
    );
    if (player && player.flvplayer) {
      player.flvplayer.play();
    }
  }

  // 暂停播放
  _recordPause(params, cb) {
    let {
      device, // 必须
      channel, // 必须
      protocolType, // 必须
      ctrlcmd,
      playspeed,
      seekpos,
    } = params;
    const type = 2; //回放
    protocolType = ~~protocolType || 1;
    ctrlcmd = ctrlcmd >= 0 ? ~~ctrlcmd : 1; // 暂停回放
    playspeed = ~~playspeed;
    seekpos = ~~seekpos;
    channel *= 1;

    let player = this._players.find(
      (p) => p.device === device && p.channel === channel && p.type === type
    );
    if (player && player.flvplayer) {
      player.flvplayer.pause();
    }

    this._recordCtrl(
      { device, channel, protocolType, ctrlcmd, playspeed, seekpos },
      cb
    );
  }

  // 倍数播放
  _recordForward(params, cb) {
    let {
      device, // 必须
      channel, // 必须
      protocolType, // 必须
      playspeed, // 必须
      ctrlcmd,
      seekpos,
    } = params;
    const type = 2; //回放
    protocolType = ~~protocolType || 1;
    ctrlcmd = ctrlcmd >= 0 ? ~~ctrlcmd : 3; // 快进回放
    playspeed = ~~playspeed; // 1：1倍; 2：2倍; 3：4倍; 4：8倍; 5：16倍
    seekpos = ~~seekpos;
    channel *= 1;

    // 下发指令, 控制推送流
    this._recordCtrl(
      { device, channel, protocolType, ctrlcmd, playspeed, seekpos },
      (res) => {
        if (res.status === 1) {
          let player = this._players.find(
            (p) =>
              p.device === device && p.channel === channel && p.type === type
          );
          if (player && player.flvplayer) {
            // 修改播放器速度
            player.flvplayer.playbackRate = 2 ** (playspeed - 1);
          }
        }

        if (cb) cb(res);
      }
    );
  }
  // 关键帧快退回放
  _recordBackward(params, cb) {
    let {
      device, // 必须
      channel, // 必须
      protocolType, // 必须
      playspeed, // 必须
      ctrlcmd,
      seekpos,
    } = params;
    const type = 2; //回放
    protocolType = ~~protocolType || 1;
    ctrlcmd = ctrlcmd >= 0 ? ~~ctrlcmd : 4; // 关键帧快退回放
    playspeed = ~~playspeed; // 1：1倍; 2：2倍; 3：4倍; 4：8倍; 5：16倍
    seekpos = ~~seekpos;
    channel *= 1;

    // 下发指令, 控制推送流
    this._recordCtrl(
      { device, channel, protocolType, ctrlcmd, playspeed, seekpos },
      (res) => {
        if (res.status === 1) {
          let player = this._players.find(
            (p) =>
              p.device === device && p.channel === channel && p.type === type
          );
          if (player && player.flvplayer) {
            // 修改播放器速度
            player.flvplayer.playbackRate = 2 ** (playspeed - 1);
          }
        }

        if (cb) cb(res);
      }
    );
  }

  // 关键帧播放
  _recordKeyFrame(params, cb) {
    let {
      device, // 必须
      channel, // 必须
      protocolType, // 必须
      ctrlcmd,
      playspeed,
      seekpos,
    } = params;

    const type = 2; //回放
    protocolType = ~~protocolType || 1;
    ctrlcmd = ctrlcmd >= 0 ? ~~ctrlcmd : 6; // 关键帧播放
    playspeed = ~~playspeed; // 0
    seekpos = ~~seekpos;
    channel *= 1;

    // 下发指令, 控制推送流
    this._recordCtrl(
      { device, channel, protocolType, ctrlcmd, playspeed, seekpos },
      (res) => {
        if (res.status === 1) {
          let player = this._players.find(
            (p) =>
              p.device === device && p.channel === channel && p.type === type
          );
          if (player && player.flvplayer) {
            // 修改播放器速度
            player.flvplayer.playbackRate = 1;
          }
        }

        if (cb) cb(res);
      }
    );
  }

  // 拖动回放 seek 播放
  _recordSeekPos(params, cb) {
    let {
      device, // 必须
      channel, // 必须
      protocolType, // 必须
      seekpos, // 必须
      ctrlcmd,
      playspeed,
    } = params;

    const type = 2; //回放
    protocolType = ~~protocolType || 1;
    ctrlcmd = ctrlcmd >= 0 ? ~~ctrlcmd : 5; // 拖动回放
    playspeed = ~~playspeed; // 0
    seekpos = ~~seekpos;
    channel *= 1;

    // 下发指令, 控制推送流
    this._recordCtrl(
      { device, channel, protocolType, ctrlcmd, playspeed, seekpos },
      (res) => {
        // TODO: 拖动播放 暂不知怎么处理播放
        // if (res.status === 1) {
        //   let player = this._players.find(p => p.device === device && p.channel === channel && p.type === type);
        //   if (player && player.flvplayer) {
        //     // 修改播放器速度
        //     player.flvplayer.playbackRate = 1;
        //   }
        // }

        if (cb) cb(res);
      }
    );
  }

  _recordCtrl(params, cb) {
    let { device, channel, protocolType, ctrlcmd, playspeed, seekpos } = params;
    protocolType = ~~protocolType || 1;
    console.log(playspeed);
    playspeed = ~~playspeed;
    console.log(playspeed);
    seekpos = ~~seekpos;
    channel *= 1;

    //// `ctrlcmd`
    // 0：开始回放
    // 1：暂停回放
    // 2：结束回放
    // 3：快进回放
    // 4：关键帧快退回放
    // 5：拖动回放
    // 6：关键帧播放

    //// `playspeed` 回放方式为3和4时，此字段内容有效，否则置0
    // 0：无效
    // 1：1倍
    // 2：2倍
    // 3：4倍
    // 4：8倍
    // 5：16倍

    // `seekpos` 采用utc时间
    // 为0时表示一直回放，回放方式为4时，该字段无效

    this._request.devRecCtrl(
      {
        device,
        channel,
        protocolType,
        ctrlcmd,
        playspeed,
        seekpos,
      },
      (res) => {
        let player = this._players.find(
          (p) => p.device === device && p.channel === channel && p.type === type
        );
        if (player && player.flvplayer) {
          player.flvplayer.play();
        }
        if (cb) cb(res);
      }
    );
  }

  _gaDownload(params, cb) {
    let {
      device,
      channel,
      begintime,
      endtime,
      protocolType,
      fileName,
      filesize,
      devLinkIp,
      codetype,
      datatype,
    } = params;
    protocolType = ~~protocolType || 2;
    devLinkIp = devLinkIp || "";

    this._request.gaDevDownReq(
      {
        device,
        channel,
        begintime,
        endtime,
        startoff: fileName,
        filesize,
        protocolType,
        devLinkIp,
      },
      (res) => {
        if (cb) cb(res);
      }
    );
  }

  _gaDownloadCtrl(params, cb) {
    let { device, channel, ctrlcmd, protocolType } = params;
    ctrlcmd = ~~ctrlcmd || 2;
    protocolType = ~~protocolType || 2;

    this._request.gaDevDownCtrl(
      {
        device,
        channel,
        ctrlcmd,
        protocolType,
        playspeed: 0,
        seekpos: 0,
      },
      (res) => {
        if (cb) cb(res);
      }
    );
  }
  //#endregion

  //#region local
  _localDiskQuery(params, cb) {
    let { diskpath } = params;
    this._request.locDiskQuery({ diskpath }, (res) => {
      const { status, info, datecount, datefile } = res;
      let dates = [];
      if (status === 1) {
        dates = datefile.split("|").reverse();
      }
      if (cb) {
        cb({ status, info, datecount, dates });
        cb = null;
      }
    });
  }

  _localQuery(params, cb) {
    let { diskpath, channel, begintime, endtime } = params;
    let receiveData = [];
    channel = channel instanceof Array ? [...channel] : [channel];
    this._request.locRecordQuery(
      {
        diskpath,
        channel: channel.reduce((a, b) => a + 2 ** (b - 1), 0),
        begintime,
        endtime,
      },
      (res) => {
        const { status, info, records, recordfile } = res;
        if (recordfile && recordfile.length) {
          receiveData = receiveData.concat(recordfile);
          if (status !== 1) return;
        }
        if (cb) {
          let recordfile = receiveData.sort(
            (a, b) => a.begintime - b.begintime
          );
          cb({ status, info, channel, records, recordfile });
          receiveData = null;
          cb = null;
        }
      }
    );
  }

  _localOpen(els, params, cb, flvMds = {}, flvConfig = {}) {
    let { diskpath, channel, begintime, endtime, seekpos, sync } = params;
    let elements = els instanceof Array ? [...els] : [els];
    let channels = channel instanceof Array ? [...channel] : [channel];
    const type = 4; //本地视频
    sync = ~~sync;

    // let channels = [1, 2, 3, 4, 5, 6, 7, 8]
    // elements = channels.filter(p => channel & (2 ** (p - 1))).map((channel, i) => {
    //     const uid = uuid()
    //     const el = elements[i]
    //     el.setAttribute('uid', uid)
    //     return { el, channel, uid }
    // })

    elements = elements.map((el, i) => {
      const uid = uuid();
      const channel = channels[i] * 1;
      if (el) el.setAttribute("uid", uid);
      return { el, channel, uid };
    });

    this._request.locRecReq(
      {
        diskpath,
        channel: channels.reduce((a, b) => a + 2 ** (b - 1), 0),
        begintime,
        endtime,
        seekpos,
        async: sync,
      },
      (res) => {
        if (
          elements.some(({ el, uid }) => el && el.getAttribute("uid") !== uid)
        )
          return;
        const { status, info, media_address } = res;

        if (status === 1) {
          elements.forEach(({ el, channel, uid }) => {
            let { video_address: url } = media_address.find(
              (p) => p.channel === channel
            );
            let player = this._players.find(
              (p) =>
                p.diskpath === diskpath &&
                p.channel === channel &&
                p.type === type
            );
            if (player) {
              this._removePlayer({ diskpath, channel, type });
            }
            let flvplayer = this._flvStart(
              el,
              { hasAudio: true, hasVideo: true, ...flvMds, url },
              { ...flvConfig }
            );
            this._players.push({
              diskpath,
              channel,
              type,
              flvplayer,
              element: el,
              flvMds: { ...flvMds },
              flvConfig: { ...flvConfig },
            });
          });
        } else if (status === -102) {
          elements.forEach((el) => {
            this._localClose({
              diskpath,
              channel: el.channel,
            });
          });
        }

        if (cb) {
          cb({ status, info, diskpath, channel, media_address });
          elements = null;
          flvMds = null;
          flvConfig = null;
          cb = null;
        }
      }
    );
  }

  _localClose(params, cb) {
    let { diskpath, channel, seekpos, ctrlcmd, playspeed } = params;
    let channels = channel instanceof Array ? [...channel] : [channel];
    const type = 4; // 本地视频

    ctrlcmd = ctrlcmd >= 0 ? ~~ctrlcmd : 2;
    playspeed = ~~playspeed;
    seekpos = ~~seekpos;

    channels.forEach((channel) => {
      this._removePlayer({ diskpath, channel, type });
    });

    this._localCtrl({ channel, ctrlcmd, playspeed, seekpos }, cb);
  }

  _localPlay(params, cb) {
    let { diskpath, channel, seekpos, ctrlcmd, playspeed } = params;
    let channels = channel instanceof Array ? [...channel] : [channel];
    const type = 4; //本地视频

    ctrlcmd = ~~ctrlcmd;
    playspeed = ~~playspeed;
    seekpos = ~~seekpos;

    this._localCtrl(
      {
        channel,
        ctrlcmd,
        playspeed,
        seekpos,
      },
      (res) => {
        const { status } = res;
        if (status === 1) {
          channels.forEach((channel) => {
            let player = this._players.find(
              (p) =>
                p.diskpath === diskpath &&
                p.channel === channel &&
                p.type === type
            );
            if (player && player.flvplayer) {
              playspeed = ~~playspeed || 1;
              player.flvplayer.playbackRate = 2 ** (playspeed - 1);
              player.flvplayer.play();
            }
          });
        }
        if (cb) cb(res);
      }
    );
  }

  _localPause(params, cb) {
    let { diskpath, channel, seekpos, ctrlcmd, playspeed } = params;
    let channels = channel instanceof Array ? [...channel] : [channel];
    const type = 4; //本地视频

    ctrlcmd = ~~ctrlcmd || 1;
    playspeed = ~~playspeed;
    seekpos = ~~seekpos;

    channels.forEach((channel) => {
      let player = this._players.find(
        (p) =>
          p.diskpath === diskpath && p.channel === channel && p.type === type
      );
      if (player && player.flvplayer) {
        player.flvplayer.pause();
      }
    });

    this._localCtrl(
      {
        channel,
        ctrlcmd,
        playspeed,
        seekpos,
      },
      cb
    );
  }

  _localForward(params, cb) {
    let { diskpath, channel, seekpos, ctrlcmd, playspeed } = params;
    let channels = channel instanceof Array ? [...channel] : [channel];
    const type = 4; //本地视频

    ctrlcmd = ~~ctrlcmd || 3;
    playspeed = ~~playspeed;
    seekpos = ~~seekpos;

    this._localCtrl(
      {
        channel,
        ctrlcmd,
        playspeed,
        seekpos,
      },
      (res) => {
        const { status } = res;
        if (status === 1) {
          channels.forEach((channel) => {
            let player = this._players.find(
              (p) =>
                p.diskpath === diskpath &&
                p.channel === channel &&
                p.type === type
            );
            if (player && player.flvplayer) {
              player.flvplayer.playbackRate = 2 ** (playspeed - 1);
            }
          });
        }
        if (cb) cb(res);
      }
    );
  }

  _localBackward(params, cb) {
    let { diskpath, channel, seekpos, ctrlcmd, playspeed } = params;
    let channels = channel instanceof Array ? [...channel] : [channel];
    const type = 4; // 本地视频

    ctrlcmd = ~~ctrlcmd || 4;
    playspeed = ~~playspeed;
    seekpos = ~~seekpos;

    this._localCtrl(
      {
        channel,
        ctrlcmd,
        playspeed,
        seekpos,
      },
      (res) => {
        const { status } = res;
        if (status === 1) {
          channels.forEach((channel) => {
            let player = this._players.find(
              (p) =>
                p.diskpath === diskpath &&
                p.channel &&
                channel &&
                p.type === type
            );
            if (player && player.flvplayer) {
              player.flvplayer.playbackRate = 2 ** (playspeed - 1);
            }
          });
        }
        if (cb) cb(res);
      }
    );
  }

  _localKeyFrame(params, cb) {
    let { diskpath, channel, seekpos, ctrlcmd, playspeed } = params;
    const type = 4; // 本地视频

    ctrlcmd = ~~ctrlcmd || 6;
    playspeed = ~~playspeed;
    seekpos = ~~seekpos;
    // let channels = [1, 2, 3, 4, 5, 6, 7, 8].filter(p => channel & (2 ** (p - 1))).map(channel => channel)
    let channels = (channel + "").split(",").map((p) => p * 1);

    this._localCtrl(
      {
        channel,
        ctrlcmd,
        playspeed,
        seekpos,
      },
      (res) => {
        const { status } = res;
        if (status === 1) {
          channels.forEach((channel) => {
            let player = this._players.find(
              (p) =>
                p.diskpath === diskpath &&
                p.channel &&
                channel &&
                p.type === type
            );
            if (player && player.flvplayer) {
              player.flvplayer.playbackRate = 1;
            }
          });
        }
        if (cb) cb(res);
      }
    );
  }

  _localCtrl(params, cb) {
    let { channel, seekpos, ctrlcmd, playspeed } = params;

    playspeed = ~~playspeed;
    seekpos = ~~seekpos;

    this._request.locRecCtrl(
      {
        channel: (channel + "")
          .split(",")
          .reduce((a, b) => a + 2 ** (b - 1), 0),
        ctrlcmd,
        playspeed,
        seekpos,
      },
      cb
    );
  }

  _localExportFile(params, cb) {
    let { diskpath, filepwd, channel, begintime, endtime, type } = params;
    type = ~~type;
    this._request.locExportFile(
      {
        diskpath,
        filepwd,
        channel: (channel + "")
          .split(",")
          .reduce((a, b) => a + 2 ** (b - 1), 0),
        begintime,
        endtime,
        type,
      },
      cb
    );
  }

  _localCloseExport(params, cb) {
    let { ctrlcmd, type } = params;
    type = ~~type;
    this._request.locExportCtrl({ ctrlcmd, type }, cb);
  }

  _localQueryExportState(params, cb) {
    //查询导出状态进度
    let { channel, begintime, endtime } = params;
    channel = channel instanceof Array ? [...channel] : [channel];
    this._request.locExportState(
      {
        channel: channel.reduce((a, b) => a + 2 ** (b - 1), 0),
        begintime,
        endtime,
      },
      cb
    );
  }
  //#endregion

  //#region ftp
  /**
   * FTP上传至服务器
   */
  _ftpDownloadToServer(params, cb) {
    let {
      device,
      channel,
      begintime,
      endtime,
      alarmSign,
      datatype,
      storgetype,
      taskCondition,
    } = params;
    datatype = ~~datatype;
    storgetype = ~~storgetype;
    taskCondition = taskCondition >= 0 ? ~~taskCondition : 7;
    this._request.cmdDevFTPDown(
      {
        device,
        channel,
        begintime,
        endtime,
        alarmSign,
        datatype,
        storgetype,
        taskCondition,
      },
      (res) => {
        if (cb) cb(res);
      }
    );
  }

  _jtDownload(params, cb) {
    let {
      device,
      channel,
      begintime,
      endtime,
      fileName,
      protocolType,
      storgetype,
      playway,
      playspeed,
      datatype,
      codetype,
      SpecialSign,
    } = params;
    protocolType = ~~protocolType || 1;
    datatype = ~~datatype; // 0：音视频; 1：音频; 2：视频; 3：音频或视频; 4：音视频和定位数据一起上传
    codetype = codetype >= 0 ? ~~codetype : 1; // 0：子码流或主码流; 1：主码流; 2：子码流;    如果此通道只传输音频，此字段置0
    storgetype = ~~storgetype; // 0： 所有存储器; 1：主存储器; 2：灾备存储器
    playway = ~~playway;
    playspeed = ~~playspeed;
    channel *= 1;
    SpecialSign = SpecialSign || 0; // 特殊协议 0:不处理;1:粤标

    let param = {
      device,
      channel,
      begintime,
      endtime,
      protocolType,
      playway,
      playspeed,
      storgetype,
      datatype,
      codetype,
      SpecialSign,
    };
    if (fileName) param.startoff = fileName;

    this._request.jtVodToHttpDownReq(param, (res) => {
      if (cb) cb(res);
    });
  }

  _ftpPause(params, cb) {
    let { device, serial, cmdType } = params;
    cmdType = ~~cmdType;
    this._request.cmdDevFTPCtrl(
      {
        device,
        serial,
        cmdType,
      },
      (res) => {
        if (cb) cb(res);
      }
    );
  }

  _ftpContinue(params, cb) {
    let { device, serial, cmdType } = params;
    cmdType = cmdType >= 0 ? ~~cmdType : 1;
    this._request.cmdDevFTPCtrl(
      {
        device,
        serial,
        cmdType,
      },
      (res) => {
        if (cb) cb(res);
      }
    );
  }

  _ftpCancel(params, cb) {
    let { device, serial, cmdType } = params;
    cmdType = cmdType >= 0 ? ~~cmdType : 2;
    this._request.cmdDevFTPCtrl(
      {
        device,
        serial,
        cmdType,
      },
      (res) => {
        if (cb) cb(res);
      }
    );
  }
  //#endregion

  _onRequestOpen(e) {
    this.emit("open", e);
  }

  _onRequestClose(e) {
    if (e && e.flag === 1) {
      this.destroyAllFlv();
    }
    this.emit("close", e);
  }

  _onRequestRegister(e) {
    this.emit("register", e);
  }
  // webSocket 事件下发返回
  _onRequestMessage(data) {
    const { status, info, CmdDownType, device, channel } = data;
    if ([30].includes(CmdDownType)) {
      //查岗
      this.emit("lookup", { ...data });
    } else if (status === 16) {
      //高清标清切换
      const { device, channel, codetype, media_address } = data;
      const type = 0;
      let player = this._players.find(
        (p) => p.device === device && p.channel === channel && p.type === type
      );
      if (player && player.codetype !== codetype) {
        const { datatype, element, flvMds, flvConfig } = player;
        const { video_address: url } = media_address; //地址变化
        // element.pause();
        // element.src = ''
        // element.removeAttribute('src')
        this._removePlayer({ device, channel, type });

        sleep(200).then(() => {
          let flvplayer = this._flvStart(
            element,
            { hasAudio: false, hasVideo: true, ...flvMds, url },
            { ...flvConfig }
          );
          this._players.push({
            device,
            channel,
            codetype,
            datatype,
            type,
            flvplayer,
            element,
            flvMds,
            flvConfig,
          });
          this.emit("switchcodetype", {
            status,
            info,
            device,
            channel,
            codetype,
            media_address,
          });
        });
      }
    } else {
      if (status === 19) {
        //视频回放高级别用户通知
        this._removePlayer({ device, channel, type: 2 });
      } else if (status === 20) {
        //实时对讲高级别用户通知
        this._recorder.close();
        this._removePlayer({ device, channel, type: 3 });
      }

      this.emit("message", data);
    }
  }

  _onHeartcheck() {
    const devChn = this._players
      .filter((p) => p.device && p.channel && p.type !== 4)
      .map((p) => `${p.device}_${p.channel}_${p.type}`)
      .join("|");
    const params = devChn ? { devChn } : {};
    this._request.cmdReqHeart(params, (res) => {
      this.emit("heart", res);
    });
  }

  _onStatisticsInfo(flvplayer, statInfo) {
    let player = this._players.find(
      (p) => p.flvplayer === flvplayer && [0, 2, 4].includes(p.type)
    );
    if (player) {
      this.emit("flvstatisticsinfo", {
        device: player.device,
        diskpath: player.diskpath,
        channel: player.channel,
        statInfo,
      });
    }
  }

  _flvStart(element, mds, config = {}) {
    mds = { type: "flv", isLive: true, hasAudio: true, hasVideo: true, ...mds };
    config = {
      // 启用线程, 高版本chrome开启硬件加速可能有问题
      enableWorker: false, //启用线程,(目前不稳定)
      // lazyLoad: true, //如果有足够的数据则端口http连接
      // lazyLoadMaxDuration: 3 * 60, // 指示为lazyLoad保存数据最大秒数
      // seekType: 'range',
      enableStashBuffer: false, // 启用ISO存储缓冲区,在不启用的情况下,如果存在网络抖动,可能会停止播放
      stashInitialSize: 128, // 1024 * 1024 * 1, //IO存储器缓冲区初始大小
      autoCleanupSourceBuffer: true,
      autoCleanupMaxBackwardDuration: 30,
      autoCleanupMinBackwardDuration: 10,
      // fixAudioTimestampGap: true, //修复音频时间戳
      ...config,
    };

    console.log("create player", mds, config);
    let flvplayer = flvjs.createPlayer(mds, config);
    flvplayer.on(flvjs.Events.LOADING_COMPLETE, ({ reset }) => {
      console.log("ws complete");
    });
    flvplayer.on(flvjs.Events.STATISTICS_INFO, (statInfo) =>
      this._onStatisticsInfo(flvplayer, statInfo)
    );
    flvplayer.on(flvjs.Events.ERROR, (mediaerror, mseerror, info) => {
      // console.trace();
      if (info.code == 11) {
        // console.log(`flv destroy, err info: ${JSON.stringify(info)}`);
        // this._flvDestroy(flvplayer);

        // 检测到异常, 重连, NOTE: 影响视频回放?
        if (flvplayer) {
          try {
            // 先断开当前连接
            flvplayer.unload();
            flvplayer.detachMediaElement();
          } catch (err) {
            //
          }

          // 重新连接
          sleep(100).then(() => {
            try {
              flvplayer.attachMediaElement(element);
              flvplayer.load();
              flvplayer.volume = 1;
              flvplayer.muted = true;
            } catch (err) {
              //
            }
          });
        }
      }
    });
    flvplayer.attachMediaElement(element);
    flvplayer.load();
    flvplayer.volume = 1;
    flvplayer.muted = true;
    // setTimeout(() => flvplayer.play(), 0);
    return flvplayer;
  }

  _flvDestroy(flvplayer) {
    console.log("_flvDestroy");
    try {
      flvplayer.pause();
      flvplayer.unload();
      flvplayer.detachMediaElement();
      flvplayer.destroy();
      flvplayer = null;
    } catch (err) {
      console.log(err);
    }
  }

  _removePlayer(param, forced = true) {
    while (true) {
      const index = this._players.findIndex((p) =>
        Object.keys(param).every((q) => param[q] == p[q])
      );
      if (index < 0) break;
      let player = this._players.splice(index, 1)[0];
      if (player.element) player.element.removeAttribute("uid");
      forced && this._flvDestroy(player.flvplayer);
      player = null;
    }
  }
}

export default ByskFlv;
