/**
 * 录制video视频
 */

class ByskVideoRecorder {
  constructor(videoEle, config = {}) {

    const { videoWidth, videoHeight } = videoEle;

    const canvasEle = document.createElement('canvas');
    document.body.appendChild(canvasEle);
    canvasEle.width = videoWidth;
    canvasEle.height = videoHeight;
    canvasEle.style.display = 'none';
    canvasEle.style.width = videoWidth + 'px';
    canvasEle.style.height = videoHeight + 'px';

    const frameRate = config.frameRate || 25;
    // mimeType: 'video/mp4; codecs="avc1.424028, mp4a.40.2"'
    // 'video/webm;codecs=vp8'
    const encodeType = 'video/webm;codecs=vp8'; // 'video/mp4' mp4
    const ctx2d = canvasEle.getContext('2d');
    ctx2d.fillStyle = 'deepskyblue';
    ctx2d.fillRect(0, 0, canvasEle.width, canvasEle.height);

    //创建MediaRecorder，设置媒体参数
    const stream = canvasEle.captureStream(frameRate);
    const recorder = new MediaRecorder(stream, {
      mimeType: encodeType
    });
    //收集录制数据
    recorder.ondataavailable = e => {
      if (!this.chunks) return;
      this.chunks.push(e.data);
    };


    this._startTime = 0; // 录制开始时间
    this._endTime = 0; // 录制结束时间
    this.videoEle = videoEle;
    this.chunks = [];
    this.frameId = null; // 录制requestAnimationFrameID
    this.timeoutId = null; // 停止录制定时ID
    this.ctx2d = ctx2d;
    this.recorder = recorder;
    this.canvasEle = canvasEle;
    this.videoWidth = videoWidth;
    this.videoHeight = videoHeight;

    this.drawFrame = this.drawFrame.bind(this);

  }

  get recordTime() { // 录制时间 秒
    return Math.ceil((this._endTime - this._startTime) / 1000);
  }

  // ArrayBuffer to Blob
  get recordBlob() {
    return new Blob(this.chunks, { type: 'video/webm' });
  }

  destroy() {
    this.stop();
    this._startTime = 0;
    this._endTime = 0;
    this.recorder = null;
    this.canvasEle.remove();
    this.chunks = null;
    this.canvasEle = null;
  }

  /**
   * 
   * @param {*} ops.timeslice 毫秒
   * @param {*} ops.timeout 秒 计划录制时间 默认3分钟
   */
  start(ops = {}) {
    const defaultTimeout = 60 * 10; // 默认10分钟
    const defaultTimesclie = 10; // 默认10毫秒

    let { timeslice, timeout } = ops;

    timeslice = Math.abs(timeslice) || defaultTimesclie;
    timeout = Math.abs(timeout) || defaultTimeout;

    this._startTime = Date.now();
    this._endTime = this._startTime;
    this.chunks = [];
    this.recorder.start(timeslice);
    this.drawFrame();

    return new Promise((resolve) => {
      this.timeoutId = setTimeout(() => resolve(this.stop()), timeout * 1000);
    });
  }

  stop() {
    // && this.recorder.state !== 'inactive'
    if (this.recorder && this.recorder.state !== 'inactive') {
      this.recorder.stop();
    }
    this._endTime = Date.now();
    cancelAnimationFrame(this.frameId);
    clearTimeout(this.timeoutId);
    this.frameId = null;
    this.timeoutId = null;

    if (!(this.chunks && this.chunks.length)) return null;

    return this.recordBlob;
  }

  drawFrame() {

    this.ctx2d.drawImage(this.videoEle, 0, 0, this.videoWidth, this.videoHeight);
    this._endTime = Date.now();

    // 录制超时自动停止录制
    // if (this.recordTime >= this._expectTime) {
    //   return this.stop();
    // }

    this.frameId = requestAnimationFrame(this.drawFrame);
  }
}

export default ByskVideoRecorder;
