<template>
  <div class="bysk-p-layout-view byskplayer-layout-wrapper">
    <div :class="wrapperClassName">
      <div>
        <!-- 遍历渲染视频 -->
        <div
          v-for="(item, index) in players"
          :key="item.id"
          :id="item.id"
          v-show="index < displayNum"
          :draggable="item.draggable"
          @drop.stop="onDrop($event, item)"
          @dragover.stop="onDragover"
          @dragstart.stop="onDragstart($event, item)"
          @mouseenter.stop="onMouseEnterPlayer(item)"
          @mouseleave.stop="onMouseLeavePlayer(item)"
          @dblclick.stop="onDblclickPlayer(item)"
          @click.stop="onClickPlayer(item)"
          class="player num"
          :style="playerStyles(item, index)"
          :class="playerClassName(item, index)"
        >
          <!-- 视频? -->
          <video
            v-if="item.videoId"
            :id="item.videoId"
            :autoplay="item.autoplay"
            :preload="item.preload"
            :loop="item.loop"
            :type="item.type"
            :poster="item.poster"
            :controls="false"
            @loadedmetadata="onVideoEvent($event, item)"
            @loadstart="onVideoEvent($event, item)"
            @pause="onVideoEvent($event, item)"
            @play="onVideoEvent($event, item)"
            @ratechange="onVideoEvent($event, item)"
            @durationchange="onVideoEvent($event, item)"
            @seeking="onVideoEvent($event, item)"
            @stalled="onVideoEvent($event, item)"
            @canplay="onVideoEvent($event, item)"
            @playing="onVideoEvent($event, item)"
            @canplaythrough="onVideoEvent($event, item)"
            @progress="onVideoEvent($event, item)"
            @timeupdate="onVideoEvent($event, item)"
            @ended="onVideoEvent($event, item)"
            @click="onVideoEvent($event, item)"
            @error="onVideoEvent($event, item)"
            @suspend="onVideoEvent($event, item)"
            @emptied="onVideoEvent($event, item)"
            @abort="onVideoEvent($event, item)"
          >
            <!-- 音频 -->
            <audio
              v-if="item.videoId"
              :id="item.audioId"
              :muted="item.muted"
              :autoplay="item.autoplay"
              :controls="false"
              @canplay="onAudioEvent($event, item)"
              @playing="onAudioEvent($event, item)"
              @canplaythrough="onAudioEvent($event, item)"
              @progress="onAudioEvent($event, item)"
              @timeupdate="onAudioEvent($event, item)"
              @ended="onAudioEvent($event, item)"
              @click="onAudioEvent($event, item)"
              @error="onAudioEvent($event, item)"
            ></audio>
          </video>

          <!-- FIXME 日志 -->
          <div v-show="item.logs.length" class="log-pane">
            <div
              v-for="(log, logIndex) in item.logs"
              :key="logIndex"
              class="log-line"
            >{{ log }}</div>
          </div>
          <!-- 重新播放按钮 -->
          <div
            v-show="item.replayBtnEnabled && !item.replayBtnHide"
            class="replay-pane"
          >
            <div class="replay-img" @click.stop="onClickReplay(item)" />
          </div>

          <!-- 车牌, 鼠标离开视频区域显示 -->
          <div
            class="plate-text"
            :style="{ bottom: item.ctrlBarHide ? 0 : '26px' }" v-if="showParameter"
          >
            <!-- 进度条勿删 -->
            <progress-bar
              v-if="isRecord && item.progress"
              class="progress"
              :min="Math.round(item.begintime)"  @click.stop="" @dblclick.stop=""
              :max="Math.round(item.endtime)"
              :value="Math.round(item.currentTime + item.seekTime)"
              @change="onChangeProgress($event, item)"
              @input="onInputProgress($event, item)"
            ></progress-bar>

            <!-- 车牌, 下载速度 -->
            <div class="text_bar" :title="item.plate">
              <span v-if="item.progress">{{ item.progressText }}</span>
              <span>{{ item.downloadSpeed.toFixed(2) }}KB/s</span>
              <div>
                {{
                [item.plate || ""]
                .filter((p) => p)
                .join("")
                }}
              </div>
                 <div>
                {{ item.channelsName || [newChannel(item.channel) || ""]
                .filter((p) => p)
                .join("")
                }}
              </div>
            </div>
          </div>

          <!-- 控制栏, 鼠标进入视频区域显示 -->
          <div v-show="!item.ctrlBarHide && showParameter" @click.stop="" @dblclick.stop="" class="ctrl-bar">
            <!-- 显示车牌 -->
            <div class="ctrl-block ctrl-plate-text">
              {{
              [item.plate || "", newChannel(item.channel) || ""]
              .filter((p) => p)
              .join(" & ")
              }}
            </div>

            <!-- 显示下载速度 -->
            <div
              class="ctrl-block ctrl-download-speed"
            >{{ item.downloadSpeed.toFixed(2) }}KB/s</div>

            <!-- 高清标清 -->
            <div
              v-show="isReal && item.codeTypeCtrlBtnEnabled"
              class="ctrl-block ctrl-codetype"
              @mouseenter="item.codeTypeCtrlPaneHide = false"
              @mouseleave="item.codeTypeCtrlPaneHide = true"
            >{{ ["高清", "标清"][item.codetype] }}</div>

            <!-- 停止按钮 -->
            <div
              v-show="item.stopCtrlBtnEnabled"
              class="ctrl-block ctrl-stop"
              title="停止"
              @click="onClickCtrlStop(item)"
            ></div>
            <!-- 暂停 -->
            <div
              v-show="item.pauseCtrlBtnEnabled"
              class="ctrl-block"
              :class="{
                'ctrl-pause': !item.paused,
                'ctrl-playing': item.paused,
              }"
              @click="onClickCtrlPause(item)"
            ></div>

            <!-- 切图按钮 -->
            <div
              v-show="item.snapshotCtrlBtnEnabled"
              class="ctrl-block ctrl-snapshot"
              title="截图"
              @click="onClickSnapshot(item)"
            ></div>

            <!-- 录制视频 -->
            <div
              v-show="item.screenRecordCtrlBtnEnabled"
              :class="{
                'ctrl-block': true,
                'ctrl-screenrecord': !item.videoRecorder,
                'ctrl-screenrecording': !!item.videoRecorder,
              }"
              :title="item.videoRecorder ? '停止录制' : '开始录制'"
              @click="onClickScreenRecord(item)"
            ></div>

            <!-- 打开声音 & 静音 -->
            <div
              v-show="item.mutedCtrlBtnEnabled"
              class="ctrl-block"
              :class="{ 'ctrl-muted': item.muted, 'ctrl-volume': !item.muted }"
              :title="item.muted ? '开启音频' : '静音'"
              @click.stop="onClickCtrlMuted(item)"
            ></div>

            <!-- 画中画 -->
            <div
              v-if="item.isPicInPic"
              class="ctrl-block ctrl-picInpic"
              title="开启画中画"
              @click="onClickCtrlPicInPic(item)"
            ></div>

            <!-- 全屏 -->
            <div
              v-show="item.fullscreenCtrlBtnEnabled"
              class="ctrl-block"
              :class="{
                'ctrl-fullscreen': !item.fullscreened,
                'ctrl-minimize': item.fullscreened,
              }"
              :title="item.fullscreened ? '退出全屏' : '全屏'"
              @click="onClickCtrlFullscreen(item)"
            ></div>

            <!-- 下载 -->
            <div
              v-show="isReal && item.downloadCtrlBtnEnabled"
              class="ctrl-block ctrl-download"
            ></div>
          </div>

          <!-- 高清标清切换 -->
          <div
            v-show="item.codeTypeCtrlBtnEnabled && !item.codeTypeCtrlPaneHide"
            class="ctrl-codetype-pane"
            @mouseenter="item.codeTypeCtrlPaneHide = false"
            @mouseleave="item.codeTypeCtrlPaneHide = true" @click.stop="" @dblclick.stop=""
          >
            <div @click="onClickCtrlCodetype(item, 0)" class="codetype-item">高清</div>
            <div @click="onClickCtrlCodetype(item, 1)" class="codetype-item">标清</div>
          </div>

          <!-- 视频录制计时 -->
          <span v-if="item.videoRecorder" class="videoRecorderP">
            {{
            timeFormat(item.videoRecorder.recordTime * 1000)
            }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ByskFlv from "./ByskFlv";
import { saveAsFile } from "@/utils/saveAsFile";

// 引入进度条
import ProgressBar from "@/components/ProgressBar";
// 引入视频录制
import VideoRecorder from "./ByskVideoRecorder";
// import fixWebmDuration from '@/utils/fixWebmDuration';

import { delay } from "@/js/common";

export default {
  name: "byskPlayerLayout",
  components: {
    ProgressBar,
  },
  data() {
    return {
      id: "bysk_player",
      intervalId: null,
      // video封面图
      poster: "",
      maxNum: 0,
      playerNum: 1,
      // 视频布局规格
      layouts: [1, 2, 4, 6, 8, 9, 12, 13, 16, 17, 25],
      players: [],
      // byskflv对象
      byskFlv: null,
      // 视频服务地址
      baseURL: "",
      username: "", //userId,
      userkey: "",
      userLevel: 1,
      // 视频布局模式, 单行模式, 单列模式
      layoutMode: "",
      isSwitchCodetypeOnFullscreen: false, //双击是否高标清切换
      showParameter: true, // 显示下边操作栏
    };
  },
  computed: {
    newChannel() {
      return function (channel) {
        switch (channel) {
          case 1:
            return '右摄像头';
          case 2:
            return '后摄像头';
          case 3:
            return '左摄像头';
          case 4:
            return '前摄像头';
          case 5:
            return '驾驶室内摄像头';
          case 6:
            return '卷扬摄像头';
        }
      };
    },

    // 判断是否是实时视频
    isReal() {
      return false;
    },
    // 判断是否是回放视频
    isRecord() {
      return false;
    },
    // 判断是否是本地播放视频
    isLocal() {
      return false;
    },
    /**
     * 显示视频数量
     */
    displayNum() {
      return this.layouts.find((p) => p >= this.playerNum) || 1;
    },
    /**
     * 布局样式
     */
    wrapperClassName() {
      if (this.singleColumn) return "single-column";
      if (this.singleLine) return "single-line";
      return `layout-${this.displayNum}`;
    },
    // 是否是单车模式
    singleLine() {
      return this.layoutMode === "singleLine";
    },
    // 是否是列展示
    singleColumn() {
      return this.layoutMode === "singleColumn";
    },
  },
  beforeMount() {
    // this.initPlayers()
    this.onExitFullscreen = this.onExitFullscreen.bind(this);
  },
  // 组件销毁
  beforeDestroy() {
    clearInterval(this.intervalId);
    this.destroy();
  },
  methods: {
    // 通过命令链路发送消息
    sendBuffer(buffer) {
      this.byskFlv.sendBuffer(buffer);
    },
    lookUp(options) {
      this.byskFlv.lookUp(options);
    },
    destroy(flag = true, options = {}) {
      let { log } = options;
      let speakObj = this.players[this.players.length - 1];

      if (speakObj && speakObj.using) {
        this.closeSpeak({
          device: speakObj.device,
          protocolType: speakObj.protocolType,
        });
      }

      this.players.forEach((p) => {
        const {
          using,
          device,
          channel,
          protocolType,
          plate,
          vehicleId,
          groupId,
        } = p;
        if (using) {
          this.close("", { device, channel, protocolType });
          this.$emit("playend", {
            device,
            channel,
            protocolType,
            plate,
            vehicleId,
            groupId,
          });
          if (log) p.logs.push(log);
        }
      });
      if (flag) this.byskFlv.destroy();
    },
    setAutoCloseTime(time) {
      this.players.forEach((p) => (p.autoCloseTime = time * 1000 * 60));
    },
    setPoster(poster) {
      this.poster = poster;
      this.players.forEach((p) => (p.poster = poster));
    },
    setPlayerNum(n = 1, maxNum = 0) {
      //this.maxNum = maxNum || this.layouts[this.layouts.length - 1];

      if (n !== this.playerNum) {
        this.players.forEach((p) => {
          p.maximized && (p.maximized = false);
          p.fullscreened && (p.fullscreened = false);
        });
        this.playerNum = n;
      }
    },
    setLayout(options) {
      if (options.hasOwnProperty("playerNum"))
        this.setPlayerNum(options.playerNum);
      if (options.hasOwnProperty("layoutMode"))
        this.layoutMode = options.layoutMode;
    },
    allocate(num, start) {
      const maxNum = this.maxNum || this.layouts[this.layouts.length - 1];
      const uses = this.players.filter((p) => p.using);
      const playerNum = start >= 0 ? start + num : uses.length + num;

      if (maxNum < playerNum) {
        return {
          flag: 0,
          msg: `申请分配视频失败,视频画面不足(最大${maxNum}画面)`,
        };
      }

      const notUses = (
        start >= 0
          ? this.players.slice(start, playerNum)
          : this.players.filter((p) => !p.using).slice(0, num)
      ).map((p) => {
        p.using = true;
        return p;
      });

      const lastNum =
        this.players.findIndex((p) => p.id === notUses[notUses.length - 1].id) +
        1;

      if (lastNum > this.displayNum) {
        this.setPlayerNum(playerNum);
      }

      return {
        flag: 1,
        msg: "申请成功",
        ids: notUses.map((p) => p.videoId),
        sorts: notUses.map((p) => p.sortId),
        elements: notUses.map((p) => p.video),
      };
    },
    timing() {
      if (!this.intervalId) {
        this.intervalId = setInterval(() => {
          let usings = this.players.filter(
            (p) => p.using && p.autoCloseTime && p.splaytime
          );
          if (usings.length) {
            const nowtime = new Date().getTime();
            usings
              .filter((p) => nowtime - p.splaytime > p.autoCloseTime)
              .forEach((p) => {
                const {
                  device,
                  channel,
                  protocolType,
                  plate,
                  vehicleId,
                  groupId,
                } = p;

                this.close("", { device, channel, protocolType }, (res) => {
                  p.logs.push("定时停止播放");
                  this.$emit("playend", {
                    device,
                    channel,
                    protocolType,
                    plate,
                    vehicleId,
                    groupId,
                    countdownEnd: true
                  });
                });
              });
          }
        }, 1000 * 30);
      }
    },
    playerClassName(item, index) {
      item.sortId = index + 1;
      return item.maximized || item.fullscreened
        ? "maximize"
        : `num-${index + 1}`;
      // return item.maximized ? 'maximize' : `num-${index + 1}`
    },
    playerStyles(item, index) {
      return {
        // background: `url(${item.poster}) no-repeat`,
        // 'background-size': '100% 100%',
      };
    },
    onMouseEnterPlayer(item) {
      item.ctrlBarHide = false;
    },
    onMouseLeavePlayer(item) {
      item.ctrlBarHide = true;
    },
    onClickPlayer(item) {
      this.$emit("click", { ...item });
    },
    onDblclickPlayer(item) {
      if (this.singleLine || this.singleColumn || item.fullscreened) return;
      const maximized = !item.maximized;
      if (maximized) {
        this.players.forEach((p) => (p.maximized = false));
        if (this.isSwitchCodetypeOnFullscreen) {
          this.onClickCtrlCodetype(item, 0);
        }
      }
      item.maximized = maximized;
    },
    onClickCtrlCodetype(item, codetype) {
      if (item.codetype === codetype) return;
    },
    onClickCtrlStop(item) { },
    onClickCtrlPause(item) {
      item.paused = !item.paused;
    },
    onClickReplay(item) {
      item.replayBtnHide = true;
      if (this.handleReplay) this.handleReplay(item);
    },
    onClickSnapshot(item) {
      const { video, using } = item;
      if (!using) return;

      const dataURL = this.snapshot(item);
      if (!dataURL) return;

      this.$emit("snapshot", { ...item, dataURL });
      saveAsFile(dataURL, `${item.plate}_${item.channel}.png`);
    },
    onClickScreenRecord(item) {
      const { using } = item || {};
      if (!using) return;

      if (item.videoRecorder) {
        this.endVideoRecord(item);
        return;
      }
      item.videoRecorder = new VideoRecorder(item.video);
      item.videoRecorder.start().then((blob) => {
        if (blob) {
          // webm添加duration,该方法无效
          // blob = await fixWebmDuration(blob, item.videoRecorder.recordTime * 1000);
          let objURL = URL.createObjectURL(blob);
          saveAsFile(objURL, `lp_${item.plate}_${item.channel}.webm`);
          URL.revokeObjectURL(objURL);
          objURL = null;
          blob = null;
        }
        item.videoRecorder.destroy();
        item.videoRecorder = null;
      });
    },
    endVideoRecord(item) {
      if (!(item && item.videoRecorder)) return;
      let blob = item.videoRecorder.stop();
      if (blob) {
        let objURL = URL.createObjectURL(blob);
        saveAsFile(objURL, `lp_${item.plate}_${item.channel}.webm`);
        URL.revokeObjectURL(objURL);
        objURL = null;
        blob = null;
      }
      item.videoRecorder.destroy();
      item.videoRecorder = null;
    },
    snapshot(item) {
      const { video, using } = item || {};
      if (!using) return;
      try {
        let canvas = document.createElement("canvas");
        canvas.width = video.videoWidth >= 1280 ? 1280 : video.videoWidth - 1;
        canvas.height = video.videoHeight >= 720 ? 720 : video.videoHeight - 1;
        canvas
          .getContext("2d")
          .drawImage(video, 0, 0, canvas.width, canvas.height);
        return canvas.toDataURL();
      } catch (error) {
        console.log(error);
      }
      return "";
    },
    onClickCtrlMuted(item) {
      if (!item.using && item.splaytime) return;
      if (item.using) {
        item.muted = !item.muted;
      } else {
        item.muted = true;
      }
      this.onCtrlMuted
        ? this.onCtrlMuted(item)
        : (item.video.muted = item.muted);
    },
    onClickCtrlFullscreen(item) {
      const { fullscreened } = item;
      item.fullscreened = !fullscreened;
      const wrapper = document.getElementById(item.id);
      if (fullscreened) {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.webkitExitFullscreen) {
          document.webkitExitFullscreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        }
      } else {
        this.removeFullscreenEvent();
        this.addFullscreenEvent();

        if (wrapper.requestFullscreen) {
          wrapper.requestFullscreen();
        } else if (wrapper.webkitRequestFullscreen) {
          wrapper.webkitRequestFullScreen();
        } else if (wrapper.mozRequestFullScreen) {
          wrapper.mozRequestFullScreen();
        } else if (wrapper.msRequestFullscreen) {
          wrapper.msRequestFullscreen();
        }
        if (this.isSwitchCodetypeOnFullscreen) {
          this.onClickCtrlCodetype(item, 0);
        }
      }
    },
    onClickCtrlPicInPic(item) {
      if (!(item.using && item.video && !item.video.paused)) return;
      item.video.requestPictureInPicture();
    },
    onChangeProgress(value) {
      console.log(1333, value);
    },
    onInputProgress(value, item) {
      console.log(1111, value);
    },
    initPlayers(option = {}) {
      this.players = [];
      //+1 最后一个player留给对讲
      for (let i = 1; i <= this.layouts[this.layouts.length - 1] + 1; i++) {
        const prefix = `${this.id}_${i}`;
        const player = {
          sortId: i,
          id: prefix,
          uid: "",
          videoId: `${this.id}_video_${i}`,
          audioId: `${this.id}_audio_${i}`,
          using: false, //是否正在使用
          videoSrc: null,
          audioSrc: null,
          flvMDS: this.createFlvMDS(),
          flvConfig: this.createFlvConfig(),
          autoCloseTime: 0,
          splaytime: 0, //播放开始时间
          poster: require("./assets/imgs/poster.png"),
          autoplay: true,
          preload: "none",
          loop: false,
          type: "video/mp4",
          muted: true,
          paused: true, //是否暂停
          networkPaused: true, //网络流是否暂停
          currentTime: 0, //同video.currentTime
          seekTime: 0, //查找时间 range:begintime~endtime
          fullscreened: false,
          maximized: false, //占满所有播放区域
          progress: 0, // 播放进度 rang:0~100, 回放有效
          progressText: "00:00/00:00", //播放进度
          isPicInPic: false, //画中画
          videoRecorder: null, // 录制视频

          draggable: true, //是否可拖动
          ctrlBarHide: true, //隐藏控制栏
          codeTypeCtrlBtnEnabled: false, //是否启用控制栏切换码率
          codeTypeCtrlPaneHide: true, //隐藏控制栏码率选择
          stopCtrlBtnEnabled: true, //是否启用控制栏停止
          pauseCtrlBtnEnabled: false, //是否启用控制栏暂停播放
          snapshotCtrlBtnEnabled: true, //是否启用控制栏截图
          screenRecordCtrlBtnEnabled: true, //是否启用控制栏录屏
          mutedCtrlBtnEnabled: true, //是否启用控制栏静音
          fullscreenCtrlBtnEnabled: true, //是否启用控制栏全屏
          downloadCtrlBtnEnabled: false, //是否启用控制栏下载按钮
          replayCtrlBtnEnabled: true, //是否启用控制栏重新播放
          replayBtnEnabled: true, //是否启用播放器上的重播放功能
          replayBtnHide: true, //是否隐藏播放器上的重播按钮
          logPaneHide: true, //是否隐藏播放器上的日志功能
          logs: [], //播放器上的日志

          plate: "",
          groupId: "",
          vehicleId: "",
          device: "",
          channel: "",
          begintime: "",
          endtime: "",
          fileName: "",
          protocolType: 1,
          codetype: 1, // 0:高清; 1:标清;
          datatype: 1, // 音视频控制
          storgetype: 0,
          playway: 0,
          playspeed: 0, // 1：1倍; 2：2倍; 3：4倍; 4：8倍; 5：16倍
          diskpath: "",
          seekpos: 0,
          sync: 0,
          SpecialSign: 0, // 特殊协议标志 0:不处理;1:粤标

          codetypeSwitching: false, //是否正在切换高清标清中
          isDragging: false, // 是否正在拖动进度条

          downloadSpeed: 0,
          _video: null,
          _audio: null,
          ...option,
        };

        Object.defineProperty(player, "video", {
          // writable: true,
          enumerable: true,
          get: function () {
            this._video = document.getElementById(this.videoId);
            return this._video;
            // return document.getElementById(this.videoId)
          },
          set: function (val) {
            this._video = val;
            let id = this.videoId;
            this.videoId = "";
            delay(() => (this.videoId = id), 200);
          },
        });
        Object.defineProperty(player, "audio", {
          // writable: true,
          enumerable: true,
          get: function () {
            this._audio = document.getElementById(this.audioId);
            // return document.getElementById(this.audioId)
            return this._audio;
          },
          set: function (val) {
            this._audio = val;
            let id = this.audioId;
            this.audioId = "";
            delay(() => (this.audioId = id), 200);
          },
        });
        player.logs.clear = (ms = 0) => {
          if (ms) {
            if (player.logs.length && !player.logs.delayId) {
              player.logs.delayId = delay(() => {
                player.logs.delayId = null;
                console.log("log clear", player.logs.length);
                player.logs.splice(0);
              }, ms);
            }
          } else {
            clearTimeout(player.logs.delayId);
            player.logs.delayId = null;
            player.logs.splice(0);
          }
        };
        player.logs.push = (val) => {
          if (player.logs.length >= 3) {
            player.logs.clear();
          }
          this.$set(player.logs, player.logs.length, val);
        };

        this.players.push(player);
      };
    },
    createFlvMDS(mds) {
      return Object.assign(
        {
          type: "flv",
          isLive: true,
          hasVideo: true,
          hasAudio: true,
        },
        mds
      );
    },
    createFlvConfig() { },
    init({
      id,
      userId,
      userkey,
      userLevel,
      platform,
      baseURL,
      isSwitchCodetypeOnFullscreen,
      isPicInPic,
      showParameter = true
    }) {
      if (id) this.id = id;
      this.baseURL = baseURL;
      this.userkey = userkey;
      this.userLevel = userLevel;
      this.showParameter = showParameter;
      this.username = userId || userkey || "api接口";
      this.isSwitchCodetypeOnFullscreen = isSwitchCodetypeOnFullscreen;

      this.initPlayers({ isPicInPic });

      this.byskFlv = new ByskFlv({
        id: this.id,
        baseURL,
        userkey,
        userLevel,
        platform,
        username: this.username,
      });

      this.byskFlv.on("open", this.onOpenCallback);
      this.byskFlv.on("close", this.onCloseCallback);
      this.byskFlv.on("register", this.onRegisterCallback);
      this.byskFlv.on("heart", this.onHeartCallback);
      this.byskFlv.on("message", this.onMessageCallback);
      this.byskFlv.on("switchcodetype", this.onSwitchCodetypeCallback);
      this.byskFlv.on("flvstatisticsinfo", this.onFlvStatisticsInfoCallback);
      this.byskFlv.on("lookup", this.onLookUpCallback);
    },
    onOpenCallback(e) {
      this.$emit("open", e);
    },
    onCloseCallback(e) {
      if (e && e.flag === 1) {
        this.players.forEach((p) => {
          p.logs.clear();
          p.logs.push(e.msg);
          p.using = false;
          p.downloadSpeed = 0;
          p.replayBtnHide = true;
          p.paused = true;
          p.currentTime = 0;
          p.splaytime = 0;
        });
      }
      this.$emit("close", e);
    },
    onRegisterCallback(e) {
      this.players.forEach((p) => {
        p.logs.clear();
        if (e.status === 1) {
          p.replayBtnHide = !p.device;
        } else {
          p.logs.push(e.info);
        }
      });
      this.$emit("register", e);
    },
    onHeartCallback(e) {
      this.$emit("heart", e);
    },
    onMessageCallback(ret) {
      let { status, info } = ret;
      if (status === 17) {
        this.destroy(false, { log: info });
      }

      if (this.onMessage) this.onMessage(ret);

      this.$emit("message", ret);
    },
    onSwitchCodetypeCallback(e) {
      this.$emit("switchcodetype", e);
    },
    onFlvStatisticsInfoCallback({ device, channel, statInfo }) {
      let player = this.players.find(
        (p) => p.using && p.device === device && p.channel === channel
      );
      if (player && statInfo.speed) {
        player.downloadSpeed = statInfo.speed;
      }
    },
    onLookUpCallback(e) {
      this.$emit("lookup", e);
    },
    onDragover(evt) {
      evt.preventDefault();
    },
    onDrop(evt, item) {
      evt.preventDefault();
      let msg = evt.dataTransfer.getData("msg");
      if (!msg) return;
      let { status, context } = JSON.parse(msg);
      let index1 = this.players.indexOf(item);
      if (status === 0) {
        if (item.id === context.id) return;
        let index2 = this.players.findIndex((p) => p.id === context.id);
        context = this.players[index2];

        const paused1 = item.paused;
        const paused2 = context.paused;

        this.$set(this.players, index1, context);
        this.$set(this.players, index2, item);

        delay(() => {
          if (!paused1) item.video.play();
          if (!paused2) context.video.play();
        }, 200);
      } else if (status === 1) {
        const { channels } = context;
        const start = index1;
        const num = channels.length;

        const players = this.players
          .slice(start, start + num)
          .filter((p) => p.using);
        players.forEach((p) => {
          const { device, channel, protocolType, plate, vehicleId, groupId } =
            p;
          this.close("", { device, channel, protocolType }, (res) => {
            this.$emit("playend", {
              device,
              channel,
              protocolType,
              plate,
              vehicleId,
              groupId,
            });
          });
        });
        delay(() => {
          const { flag, ids, sorts } = this.allocate(num, start);
          if (flag && ids.length) {
            const {
              device,
              protocolType,
              plate,
              vehicleId,
              groupId,
              SpecialSign,
            } = context;
            if (protocolType === 1) {
              // GF1078
              channels.forEach((channel, i) => {
                this.open(
                  ids[i],
                  {
                    device,
                    channel: channel,
                    protocolType,
                    codetype: ~~!(sorts[i] === 1),
                    SpecialSign,
                    plate,
                    vehicleId,
                    groupId,
                  },
                  (res) => {
                    if ([1, 2].indexOf(res.status) < 0) {
                      this.$emit("playend", {
                        device,
                        channel,
                        protocolType,
                        plate,
                        vehicleId,
                        groupId,
                      });
                    } else {
                      if (res.status === 2) {
                        this.$emit("playstart", {
                          vid: ids[i],
                          device,
                          channel,
                          protocolType,
                          plate,
                          vehicleId,
                          groupId,
                        });
                      }
                    }
                  }
                );
              });
            } else {
              //GA
              this.open(
                ids,
                {
                  device,
                  channel: channels.join(","),
                  protocolType,
                  SpecialSign,
                  plate,
                  vehicleId,
                  groupId,
                },
                (res) => {
                  if ([1, 2].indexOf(res.status) < 0) {
                    channels.forEach((p) => {
                      this.$emit("playend", {
                        device,
                        channel: p,
                        protocolType,
                        plate,
                        vehicleId,
                        groupId,
                      });
                    });
                  } else {
                    if (res.status === 2) {
                      channels.forEach((p, i) => {
                        this.$emit("playstart", {
                          vid: ids[i],
                          device,
                          channel: p,
                          protocolType,
                          plate,
                          vehicleId,
                          groupId,
                        });
                      });
                    }
                  }
                }
              );
            }
          }
        }, 500);
      }
      evt.dataTransfer.clearData("msg");
    },
    onDragstart(evt, item) {
      evt.dataTransfer.setData(
        "msg",
        JSON.stringify({
          status: 0,
          context: item,
        })
      );
      let img = new Image();
      img.src = require("./assets/imgs/drag.png");
      evt.dataTransfer.setDragImage(img, 16, 16);
    },
    onVideoEvent(e, item) {
      if (!item.using || item.paused) return;
      // console.log(1233, e.type);
      switch (e.type) {
        case "loadstart":
          break;
        case "loadedmetadata":
          break;
        // case 'canplay':
        // case 'canplaythrough':
        case "playing":
          break;
        case "timeupdate":
          if (item.using && !(item.codetypeSwitching || item.isDragging)) {
            //如果正在切换高清标清或拖动进度播放,则不清除日志
            item.logs.clear(300);
          }
          item.replayBtnHide = true;
          break;
        case "ended":
          break;
        case "error":
          // console.log('video error: ', e.target.error)
          return;
      }
      if (!e.target.src) return;
      this.videoEventHandler(e, item);
    },
    onAudioEvent(e, item) {
      this.audioEventHandler(e, item);
    },
    videoEventHandler(e, item) { },
    audioEventHandler(e, item) { },
    addFullscreenEvent() {
      //添加全屏事件
      console.log("addFullscreenEvent");
      document.addEventListener("fullscreenchange", this.onExitFullscreen);
      // document.addEventListener('webkitfullscreenchange', this.onExitFullscreen)
      document.addEventListener("mozfullscreenchange", this.onExitFullscreen);
    },
    removeFullscreenEvent() {
      console.log("removeFullscreenEvent");
      // document.removeEventListener('webkitfullscreenchange', this.onExitFullscreen)
      document.removeEventListener(
        "mozfullscreenchange",
        this.onExitFullscreen
      );
      document.removeEventListener("fullscreenchange", this.onExitFullscreen);
    },
    onExitFullscreen() {
      let fullscreenElement =
        document.fullscreenElement ||
        document.webkitFullscreenElement ||
        document.mozFullscreenElement;
      if (!fullscreenElement) {
        this.removeFullscreenEvent();
        this.players.forEach((p) => {
          p.fullscreened = false;
          p.maximized = false;
        });
      }
    },
    getVisibilityProperty() {
      let visibilitychange = "visibilitychange";
      let hidden = "hidden";
      let visibilitystate = "visibilityState";
      let prefixes = ["webkit", "moz", "ms", "o"];
      for (let i = 0; i < prefixes.length; i++) {
        if (prefixes[i] + "Hidden" in document) {
          visibilitychange = prefixes[i] + visibilitychange;
          hidden = prefixes[i] + "Hidden";
          visibilitystate = prefixes[i] + "VisibilityState";
          return { visibilitychange, hidden, visibilitystate };
        }
      }
      return { visibilitychange, hidden, visibilitystate };
    },
    timeFormat(mss) {
      let days = parseInt(mss / (1000 * 60 * 60 * 24));
      let hours = parseInt((mss % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      let minutes = parseInt((mss % (1000 * 60 * 60)) / (1000 * 60));
      let seconds = parseInt(mss % (1000 * 60)) / 1000;
      if (mss >= 86400000) {
        return [days, hours, minutes, seconds]
          .map((t) => t.toString().padStart(2, "0"))
          .join(":");
      }
      if (mss >= 3600000) {
        return [hours, minutes, seconds]
          .map((t) => t.toString().padStart(2, "0"))
          .join(":");
      }
      if (mss >= 60000) {
        return [minutes, seconds]
          .map((t) => t.toString().padStart(2, "0"))
          .join(":");
      }
      if (mss > 1000) {
        return [0, seconds].map((t) => t.toString().padStart(2, "0")).join(":");
      }
      return "00:00";
    },
  },
};
</script>


<style lang='scss' scoped>
@import './assets/styles/layout.scss';

.byskplayer-layout-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  font-size: 12px;

  .player {
    background-color: rgb(81, 111, 138);
    z-index: 9;
  }

  video {
    width: 100%;
    height: 100%;
    object-fit: fill;
  }

  .plate-text {
    border-radius: 1px;
    width: calc(100% - 10px);
    height: 26px;
    line-height: 26px;
    color: rgb(255, 255, 255);
    font-size: 12px;
    background: rgba(0, 0, 0, 0);
    position: absolute;
    bottom: 0px;
    display: block;
    user-select: none;
    transition: opacity 0.3s ease 0s;
    opacity: 1;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    margin-right: 10px;
    & > div:first-child {
      flex-grow: 1;
    }
    .text_bar {
      width: 20%;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      text-align: end;
      height: 140px;
    }
    .progress {
      margin: 0 10px;
    }
  }

  .ctrl-bar {
    display: flex;
    justify-content: flex-end;
    flex-wrap: nowrap;
    align-items: center;
    border-radius: 1px;
    width: calc(100% - 1px);
    height: 26px;
    line-height: 26px;
    color: rgb(255, 255, 255);
    font-size: 12px;
    padding-left: 3px;
    background-color: rgb(250, 250, 250);
    overflow: hidden;
    transition: opacity 0.3s ease 0s;
    position: absolute;
    bottom: 0px;
    user-select: none;
    opacity: 1;
  }

  .ctrl-block {
    margin-right: 2px;
    width: 26px;
    height: 100%;
    background-size: 20px;
    background-color: initial;
    color: inherit;
    box-sizing: border-box;
    background-repeat: no-repeat;
    background-position: center center;
    overflow: hidden;
    cursor: pointer;
    &:hover {
      border: 1px solid #95a5a6;
    }
  }

  .ctrl-plate-text {
    flex-grow: 1;
    color: rgb(90, 90, 90);
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  .ctrl-download-speed {
    color: #5a5a5a;
    width: 65px;
    text-align: end;
  }
  .ctrl-codetype {
    color: rgb(72, 133, 212);
  }
  .ctrl-stop {
    background-image: url(./assets/imgs/stop.png);
  }
  .ctrl-snapshot {
    background-image: url(./assets/imgs/snapshot.png);
  }
  .ctrl-screenrecord {
    background-image: url(./assets/imgs/bsj-lz.png);
  }
  .ctrl-screenrecording {
    background-image: url(./assets/imgs/bsj-lzing.png);
  }
  .ctrl-muted {
    background-image: url(./assets/imgs/muted.png);
  }
  .ctrl-volume {
    background-image: url(./assets/imgs/sound-on.png);
  }
  .ctrl-fullscreen {
    background-image: url(./assets/imgs/fullscreen.png);
  }
  .ctrl-minimize {
    background-image: url(./assets/imgs/minimize.png);
  }
  .ctrl-pause {
    background-image: url(./assets/imgs/pause.png);
  }
  .ctrl-playing {
    background-image: url(./assets/imgs/play.png);
  }
  .ctrl-download {
    background-image: url(./assets/imgs/download.png);
  }
  .ctrl-picInpic {
    background-image: url(./assets/imgs/pic-in-pic.png);
  }
  .ctrl-codetype-pane {
    position: absolute;
    bottom: 26px;
    width: 60px;
    right: 126px;
    background-color: rgba(0, 0, 0, 0.5);
    color: rgb(255, 255, 255);
    cursor: pointer;
    .codetype-item {
      padding: 5px 0px;
      text-align: center;
      &:hover {
        background-color: rgba(41, 182, 246, 0.4);
      }
    }
  }
  .log-pane {
    border-radius: 1px;
    width: 100%;
    height: 40%;
    line-height: 16px;
    color: rgb(255, 255, 255);
    font-size: 12px;
    padding-left: 8px;
    padding-right: 5px;
    background: rgba(0, 0, 0, 0);
    position: absolute;
    bottom: 0px;
    text-align: center;
    align-items: center;
    justify-content: center;
    user-select: none;
    transition: opacity 0.3s ease 0s;
    .log-line {
      line-height: 16px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      font-size: 13px;
    }
  }
  .replay-pane {
    border-radius: 1px;
    width: 100%;
    height: 100%;
    color: rgb(255, 255, 255);
    background: rgba(0, 0, 0, 0);
    position: absolute;
    bottom: 0px;
    align-items: center;
    justify-content: center;
    user-select: none;
    display: flex;
    z-index: 99;
    .replay-img {
      width: 44px;
      height: 44px;
      cursor: pointer;
      background-image: url('./assets/imgs/play-2.png');
      background-repeat: round;
      &:hover {
        background-image: url('./assets/imgs/play-3.png');
      }
    }
  }

  .videoRecorderP {
    position: absolute;
    right: 5px;
    bottom: 30px;
    padding: 2px;
    background-color: rgb(0, 0, 0);
    color: #7aff00;
    display: inline-block;
  }
}
</style>

