/**
 * 录音
 */
class ByskRecorder {
  /**
   * 构造实例
   */
  constructor(config) {
    config = config || {};
    this._audioContext = null;
    this.recordingObj = null;
    this._microphone = null;
    this._processor = null;
    this._bufferSize = config.bufferSize || 1024;
    this._inputChannel = config.inputChannel || 1;
    this._outputChannel = config.outputChannel || 1;
  }

  get topWindow() {
    return window;// window.top === window ? window : window.top;
  }

  /**
   * 检测浏览器支持情况
   */
  get supported() {
    return this.topWindow.navigator.getUserMedia ||
    this.topWindow.navigator.webkitGetUserMedia ||
    this.topWindow.navigator.mozGetUserMedia;;
  }

  destroyed() {
    this.close();
  }


  /**
   * 断开连接
   */
  close() {
    if (this._audioContext) {
      this._microphone.disconnect();
      this._processor.disconnect();
      // this.supported.stop();
      this._audioContext.close();
      this.recordingObj.stop();
    }
    this._microphone = null;
    this._processor = null;
    this._audioContext = null;
    this.recordingObj = null;
    // console.log('结束录音');
  }
  // 开始采集
  start(reslove) {
    if (!this.supported) {
      console.log('当前浏览器不支持录音功能');
      if (reslove) reslove({ status: -201, info: '当前浏览器不支持录音功能' });
      return;
    }
    // 请求录音, 需要用户同意
    this.topWindow.navigator.getUserMedia({
      audio: true,
      // audio: {
      //   autoGainControl: true,
      //   echoCancellation: true,
      //   noiseSuppression: true,
      //   sampleRate: 48000,
      //   sampleSize: 16,
      //   latency: 0.01,
      //   channelCount: 1,
      //   volume: 0.16470588235294117
      // },
    }, stream => {
      this.recordingObj = stream
      // 关闭所有录音方法
      this.recordingObj.stop = function () {
        this.getAudioTracks().forEach(function (track) {
          track.stop();
        });
        this.getVideoTracks().forEach(function (track) { //in case... :)
          track.stop();
        });
      };
      this._audioContext = new this.topWindow.AudioContext();
      this._microphone = this._audioContext.createMediaStreamSource(stream);
      // 第一个参数表示收集采样的大小，采集完这么多后会触发 onaudioprocess 接口一次，该值一般为1024,2048,4096等，一般就设置为4096
      // 第二，三个参数分别是输入的声道数和输出的声道数，保持一致即可。
      this._processor = this._audioContext.createScriptProcessor(this._bufferSize, this._inputChannel, this._outputChannel);
      this._microphone.connect(this._processor);
      this._processor.connect(this._audioContext.destination);
      this._processor.onaudioprocess = evt => {
        //https://developer.mozilla.org/zh-CN/docs/Web/API/AudioBuffer/getChannelData
        let array = evt.inputBuffer.getChannelData(0); // Float32Array类型的pcm数据 得到了左声道(0)4096个样本数据，1是右声道的数据，
        reslove({ status: 2, buffer: array });
      };
      console.log(`开始录音: ${this._audioContext.sampleRate} ${this._audioContext.sampleSize}`);  // 采样率:44100 采样位数:32bit
      if (reslove) reslove({ status: 1, info: '开始录音' });
    }, error => {
      console.log(error);
      let info;
      switch (error.name || error.code) {
        case 'Permission denied':
        case 'PermissionDeniedError':
        case 'PERMISSION_DENIED':
        case 'NotAllowedError':
          info = '用户拒绝访问麦克风';
          break;
        case 'NOT_SUPPORTED_ERROR':
        case 'NotSupportedError':
          info = '浏览器不支持麦克风';
          break;
        case 'NotFoundError':
        case 'MANDATORY_UNSATISFIED_ERROR':
        case 'MandatoryUnsatisfiedError':
          info = '找不到麦克风设备或设备异常';
          break;
        default:
          info = '无法打开麦克风';
          console.log(info, (error.message || error.name || error.code));
          break;
      }
      if (reslove) reslove({ status: 0, info });
    });
  }
}

export default ByskRecorder;