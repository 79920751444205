<template>
  <div class="progress-outer" @click.stop="1===1">
    <div ref="slider" :class="barRunwayClass" @click="onSliderClick">
      <div class="slider_bar" :style="sliderBarStyles"></div>
      <div
        :draggable="true"
        :class="buttonOuterClass"
        :style="sliderButtonStyles"
        @drop.stop.prevent="1==1"
        @dragover.stop.prevent="1==1"
        @dragstart.stop.prevent="1==1"
        @mousedown.stop.prevent="onMousedown"
      >
        <div :class="buttonClass"></div>
      </div>
    </div>
  </div>
</template>

<script>
import {debounce,throttle} from 'lodash';

export default {
  name: 'ProgressBar',
  model: {
    prop: 'value',
    event: 'input',
  },
  props: {
    value: { //绑定值
      type: Number,
      default: 0,
    },
    min: { //最小值	
      type: Number,
      default: 0,
    },
    max: { //最大值
      type: Number,
      default: 100,
    },
    disabled: { //是否禁用
      type: Boolean,
      default: false,
    },
    step: { //步长
      type: Number,
      default: 1,
    },
    debounce: { //去抖延迟，毫秒
      type: Number,
      default: 300
    }
  },
  data() {
    this.clientRect = null;
    return {
      currentValue: this.value,
      isDragging: false,
      startX: 0,
      currentX: 0,
      startPosition: 0,
      newPosition: 0,
    };
  },
  computed: {
    barRunwayClass() {
      return [
        'slider_runway',
        this.disabled ? 'disabled' : '',
      ]
    },
    buttonOuterClass() {
      return [
        'slider_button_outer',
        this.isDragging ? 'dragging' : '',
      ];
    },
    buttonClass() {
      return [
        'slider_button',
        this.isDragging ? 'dragging' : '',
      ];
    },
    currentPosition() {
      return `${(this.currentValue - this.min) / (this.max - this.min) * 100}%`;
    },
    sliderBarStyles() { //移动过的样式
      return {
        width: this.currentPosition,
      }
    },
    sliderButtonStyles() {
      return {
        left: this.currentPosition,
      }
    },
    precision() {
      let precisions = [this.min, this.max, this.step].map(item => {
        let decimal = ('' + item).split('.')[1];
        return decimal ? decimal.length : 0;
      });
      return Math.max(...precisions);
    },
  },
  watch: {
    value(val) {
      if (this.currentValue === val || this.isDragging) return;
      this.currentValue = val;
      // console.log(this.currentPosition);
    }
  },
  beforeMount() {
    this.onMousedown = this.onMousedown.bind(this);
    this.onDragEnd = this.onDragEnd.bind(this);
    this.onDragging = this.onDragging.bind(this);
    this.currentValue = this.value;
  },
  mounted() {
  },
  beforeDestroy() {
    window.removeEventListener('mousemove', this.onDragging);
    window.removeEventListener('mouseup', this.onDragEnd);
    window.removeEventListener('contextmenu', this.onDragEnd);
  },
  methods: {
    onSliderClick(event) {
      if (this.disabled || this.isDragging) return;

      const { slider } = this.$refs;
      if (!slider) return;

      const { left } = slider.getBoundingClientRect();
      this.setPosition((event.clientX - left) / slider.clientWidth * 100);
      this.emitChange();

    },
    onDragStart(event) {
      if (this.disabled) return;
      this.isDragging = true;
      this.startX = event.clientX;
      this.startPosition = parseFloat(this.currentPosition);
    },
    onMousedown(event) {
      this.onDragStart(event);
      window.addEventListener('mousemove', this.onDragging);
      window.addEventListener('mouseup', this.onDragEnd);
      window.addEventListener('contextmenu', this.onDragEnd);
    },
    onDragEnd() {
      window.removeEventListener('mousemove', this.onDragging);
      window.removeEventListener('mouseup', this.onDragEnd);
      window.removeEventListener('contextmenu', this.onDragEnd);
      if (!this.isDragging) return;
      setTimeout(() => {
        this.isDragging = false;
        this.setPosition(this.newPosition);
        this.emitChange();
      }, 0);
    },
    onDragging(event) {
      if (!this.isDragging) return;

      const { slider } = this.$refs;
      if (!slider) return;

      this.currentX = event.clientX;
      const diff = (this.currentX - this.startX) / slider.clientWidth * 100;
      this.newPosition = this.startPosition + diff;
      this.setPosition(this.newPosition);
    },
    setPosition(percent) {
      if (percent === null || isNaN(percent)) return;
      if (percent < 0) {
        percent = 0;
      } else if (percent > 100) {
        percent = 100;
      }

      const lengthPerStep = 100 / ((this.max - this.min) / this.step);
      const steps = Math.round(percent / lengthPerStep);
      let value = steps * lengthPerStep * (this.max - this.min) * 0.01 + this.min;
      value = parseFloat(value.toFixed(this.precision));

      this.currentValue = value;
      this.$emit('input', value);
    },
    emitChange() {
      this.$emit('change', this.currentValue)
    }
  }
}
</script>

<style lang="scss" scoped>
.progress-outer {
  // position: relative;
  // width: 100%;
  // margin-right: 20px;

  &::before {
    display: table;
    content: '';
  }
  &::after {
    display: table;
    content: '';
    clear: both;
  }

  .slider_runway {
    width: 100%;
    height: 10px;
    // margin: 16px 0;
    background-color: #e4e7ed;
    border-radius: 3px;
    position: relative;
    cursor: pointer;
    vertical-align: middle;
  }
  .slider_bar {
    height: 10px;
    background-color: #409eff;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    position: absolute;
  }
  .slider_button_outer {
    // height: 36px;
    // width: 36px;
    position: absolute;
    z-index: 1001;
    top: -3px; // -15px;
    transform: translateX(-50%);
    background-color: transparent;
    text-align: center;
    user-select: none;
    line-height: normal;
    &::after {
      content: '';
      height: 100%;
      // display: inline-block;
      vertical-align: middle;
    }
    &:hover {
      cursor: grab;
    }
  }
  .slider_button {
    width: 16px;
    height: 16px;
    border: 2px solid #409eff;
    background-color: #fff;
    border-radius: 50%;
    transition: 0.2s;
    user-select: none;
    box-sizing: border-box;
    &:hover {
      cursor: grab;
      transform: scale(1.2);
    }
  }
  .dragging {
    cursor: grabbing;
  }

  .disabled {
    cursor: default;
    .slider_bar {
      background-color: #c0c4cc;
    }
    .slider_button {
      border-color: #c0c4cc;
      &:hover {
        cursor: not-allowed;
      }
    }
  }
}
</style>