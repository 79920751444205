
<script>

import PlayerMixin from './ByskPlayer';

import {
  delay,
} from '@/js/common';
import { uuid } from '@/utils/uuid';

export default {
  name: 'ByskPlayerLocal',
  mixins: [PlayerMixin],
  // extends: PlayerMixin,
  data() {
    return {
      id: 'local'
    };
  },
  computed: {
    isLocal() {
      return true;
    },
    local() {
      return this.byskFlv.local;
    }
  },
  beforeMount() {
  },
  beforeDestroy() {

  },
  errorCaptured(error, vm, info) {
    console.log(error, vm, info);
  },
  methods: {
    diskQuery(params, cb) {
      let { diskpath } = params;
      this.local.diskQuery({ diskpath }, cb);
    },
    query(params, cb) {
      let { diskpath, channel, begintime, endtime } = params;
      this.local.query({ diskpath, channel, begintime, endtime }, cb);
    },
    open(ids, params, cb, flvMds = {}, flvConfig = {}) {
      let { diskpath, channel, begintime, endtime, seekpos, sync } = params;
      ids = ids instanceof Array ? [...ids] : [ids];
      let channels = channel instanceof Array ? [...channel] : [channel];
      let players = this.players.filter(p => ids.indexOf(p.videoId) > -1);
      let uid = uuid();
      players.forEach((player, i) => {
        player.uid = uid;
        player.diskpath = diskpath;
        player.channel = channels[i] * 1;
        player.begintime = begintime;
        player.endtime = endtime;
        player.seekpos = seekpos;
        player.sync = sync;
        player.playspeed = 1;
        player.networkPaused = false;
        player.using = true;
        player.paused = false;
        player.replayBtnHide = true;
        player.pauseCtrlBtnEnabled = true;
        player.replayBtnEnabled = false;
        player.currentTime = 0;
        player.downloadSpeed = 0;
        player.splaytime = 0;
      });
      this.timing();

      const start = (count) => {
        players = players.filter(p => p.using && p.uid === uid);
        if (!players.length) return;
        players.forEach(player => {
          player.logs.clear();
          player.logs.push(`正在请求视频,${count}`);
          player.currentTime = 0;
        });
        delay(() => {
          players = players.filter(p => p.using && p.uid === uid);
          if (!players.length) return;
          this.local.open(
            players.map(p => p.video),
            { diskpath, channel, begintime, endtime, seekpos, sync },
            res => {
              players = players.filter(p => p.using && p.uid === uid);
              players.forEach(player => player.logs.push(res.info));
              if ([-1, -2, -102].indexOf(res.status) > -1 && count < 4) {
                start(++count);
                return;
              }
              players.forEach(player => {
                if ([1, 2].indexOf(res.status) < 0) {
                  player.uid = '';
                  player.using = false;
                  player.paused = true;
                  player.video.muted = true;
                  player.replayBtnHide = false;
                  player.video = null;
                  player.currentTime = 0;
                  player.downloadSpeed = 0;
                  player.splaytime = 0;
                } else if (res.status === 1) {
                  player.logs.push('设备响应成功,视频加载中...');
                  //// player.logs.clear()
                }
              });

              if (cb) cb(res);
            }, flvMds, flvConfig);
        }, count > 1 ? 2000 : 0);
      };

      start(1);
    },
    close(params, cb) {
      let { diskpath, channel } = params;
      let channels = channel instanceof Array ? [...channel] : [channel];
      this.local.close({ diskpath, channel }, cb);
      this.players
        .filter(p => p.diskpath === diskpath && channels.includes(p.channel) && p.using)
        .forEach(player => {
          player.uid = '';
          player.paused = true;
          player.muted = true;
          player.using = false;
          player.replayBtnHide = false;
          player.networkPaused = true;
          player.currentTime = 0;
          player.downloadSpeed = 0;
          player.splaytime = 0;
          player.logs.clear();
          if (player.video) {
            player.video.removeAttribute('vid');
            player.video.muted = true;
            player.video = null;
          }
        });
    },
    play(params, cb) {
      let { diskpath, channel, playspeed } = params;
      let channels = channel instanceof Array ? [...channel] : [channel];
      this.local.play({ diskpath, channel, playspeed }, cb);
      this.players
        .filter(p => p.diskpath === diskpath && channels.includes(p.channel) && p.using)
        .forEach(player => {
          player.paused = false;
          player.playspeed = playspeed;
          player.networkPaused = false;
        });
    },
    pause(params, cb) {
      let { diskpath, channel } = params;
      let channels = channel instanceof Array ? [...channel] : [channel];
      this.local.pause({ diskpath, channel }, cb);
      this.players
        .filter(p => p.diskpath === diskpath && channels.includes(p.channel) && p.using)
        .forEach(player => {
          player.paused = true;
          player.networkPaused = true;
        });
    },
    forward(params, cb) {
      let { diskpath, channel, playspeed } = params;
      let channels = channel instanceof Array ? [...channel] : [channel];
      this.local.forward({ diskpath, channel, playspeed }, cb);
      this.players
        .filter(p => p.diskpath === diskpath && channels.includes(p.channel) && p.using)
        .forEach(player => {
          player.playspeed = playspeed;
        });
    },
    backward(params, cb) {
      let { diskpath, channel, playspeed } = params;
      let channels = channel instanceof Array ? [...channel] : [channel];
      this.local.backward({ diskpath, channel, playspeed }, cb);
      this.players
        .filter(p => p.diskpath === diskpath && channels.includes(p.channel) && p.using)
        .forEach(player => {
          player.playspeed = playspeed;
        });
    },
    keyframe(params, cb) {

    },
    exportFile(params, cb) {
      let { diskpath, filepwd, channel, begintime, endtime, type } = params;
      this.local.exportFile({ diskpath, filepwd, channel, begintime, endtime, type }, cb);
    },
    closeExport(params, cb) {
      let { type } = params;
      this.local.closeExport({ type }, cb);
    },
    queryExportState(params, cb) {
      //查询导出状态进度
      let { channel, begintime, endtime } = params;
      this.local.queryExportState({ channel, begintime, endtime }, cb);
    },
    onClickCtrlStop(item) {
      const { diskpath, channel, using, stopCtrlBtnEnabled } = item;
      if (diskpath && channel && using && stopCtrlBtnEnabled) {
        if (this.playendcb) this.playendcb({ diskpath, channel });
        this.close({ diskpath, channel }, res => { });
      }
    },
    onClickCtrlPause(item) {
      let { diskpath, channel, playspeed, paused, sync } = item;
      if (!sync) return;
      paused ? this.play({ diskpath, channel, playspeed }) : this.pause({ diskpath, channel, playspeed });
      // item.paused = !paused
    },
    handleReplay(item) {
      let { videoId, diskpath, channel, begintime, endtime, seekpos, sync } = item;
      if (diskpath && channel) {
        if (this.players.some(p => p.using && p.diskpath === diskpath && p.channel === channel)) {
          item.logs.push('该设备通道已在播放');
          return;
        }

        this.open(videoId, { diskpath, channel, begintime, endtime, seekpos, sync }
          , res => {
            if ([1, 2].indexOf(res.status) < 0) {
              if (this.playendcb) this.playendcb({ diskpath, channel, begintime, endtime, seekpos, sync });
            } else {
              if (this.playstartcb) this.playstartcb({ diskpath, channel, begintime, endtime, seekpos, sync });
            }
          });
      }
    },
    onClickPlayer(item) {
      const { diskpath, channel, begintime, endtime } = item;
      if (this.clickcb) this.clickcb({ diskpath, channel, begintime, endtime });
    },
    onFlvStatisticsInfoCallback({ diskpath, channel, statInfo }) {
      let player = this.players.find(p => p.using && p.diskpath === diskpath && p.channel === channel);
      if (player && statInfo.speed) {
        player.downloadSpeed = statInfo.speed;
      }
    },
    videoEventHandler(e, item) {
      switch (e.type) {
        case 'canplay':
        case 'playing':
        case 'canplaythrough':
          if (document[this.getVisibilityProperty().hidden] && !item.paused && item.video) {
            item.video.play();
            this.changeVideoCurrentTime(item);
          }
          break;
        case 'timeupdate':
        case 'progress':
          if (item.video && !item.paused) {
            if (item.video.paused) item.video.play();
            this.changeVideoCurrentTime(item);
          }
          break;
      }
    },
    changeVideoCurrentTime(item) {
      let { diskpath, channel, playspeed, networkPaused } = item;
      let { buffered, currentTime, playbackRate } = item.video;
      let basePlaybackRate = 2 ** (playspeed - 1);
      if (buffered.length > 0) {
        const end = buffered.end(0);
        if (end < currentTime) {
          item.video.currentTime = end - 0.1;
        } else if (item.currentTime === currentTime && end - currentTime > 5) {
          item.video.currentTime += 0.3;
        }
        // else if (end - currentTime > 30 && playbackRate !== basePlaybackRate * 2 && playbackRate < 8.1) {
        //     item.video.playbackRate = basePlaybackRate * 2 > 8 ? 8.1 : basePlaybackRate * 2
        // } else if (end - currentTime < 10 && playbackRate !== basePlaybackRate) {
        //     item.video.playbackRate = basePlaybackRate * 1
        // } 
        else if (end - currentTime > 30 && !networkPaused) {
          item.networkPaused = true;
          this.local.ctrl({ channel, ctrlcmd: 1 });
        } else if (end - currentTime < 5 && networkPaused) {
          item.networkPaused = false;
          this.local.ctrl({ channel, ctrlcmd: 0 });
        }
      } else if (currentTime > 0) {
        item.video.currentTime = 0;
      }
      item.currentTime = item.video.currentTime;
      if (!item.splaytime && item.currentTime) {
        item.splaytime = Date.now();
      }
    }
  }
}
</script>
