import ByskWebSocket from "./ByskWebSocket";
import store from "@/store";
import { uuid } from "@/utils/uuid";

/**
 * 封装视频下发指令
 */

class ByskRequest {
  constructor(config) {
    const {
      id,
      baseURL,
      username,
      userkey,
      userLevel,
      platform,
      open,
      close,
      register,
      heart,
      message,
      reconnectEnable,
    } = config;
    // 用户登录token, 或SDK商务分配
    this._userkey = userkey;
    // 用户级别
    this._userLevel = userLevel || 1;
    this._platform = platform || "";
    this._opencb = open;
    this._closecb = close;
    this._registercb = register;
    this._heartcb = heart;
    this._messagecb = message;
    this._tokenKey = `__ws__${id}__`;

    let token = uuid(); //一个ws链接一个token
    // let token = sessionStorage.getItem(this._tokenKey);
    // if (!token) {
    //   token = uuid();
    //   sessionStorage.setItem(this._tokenKey, token);
    // }

    // 建立视频服务websocket连接
    this._request = new ByskWebSocket({
      wsid: id, // 唯一Id
      token, // 前端生产的唯一Id
      baseURL, // 视频服务地址
      username, // 登录用户名
      reconnectEnable, // 是否重连, 默认重连
      open: this._onRequestOpen.bind(this),
      close: this._onRequestClose.bind(this),
      heart: this._onRequestHeart.bind(this),
      message: this._onRequestMessage.bind(this),
    });
  }

  /**
   * 消耗链路
   */
  destroy() {
    if (this._request) {
      this._request.destroy();
    }
    this._request = null;
  }

  /**
   * websocket 向视频服务发送消息
   */
  sendBuffer(buffer) {
    this._request.send(buffer);
  }

  //#region 实时音视频
  /**
   * 请求实时视频
   * @param params.device - 终端号
   * @param params.channel - 通道号
   * @param params.datatype - 数据类型    0: 音视频; 1: 视频; 2: 双向对讲; 3: 监听; 4: 中心广播; 5: 透传
   * @param params.codetype - 码流类型    0: 主码流; 1: 子码流
   * @param params.devLinkIp - 服务器地址   0: 专网客户设备连接地址
   * @param params.SpecialSign - 特殊协议处理标志   0: 默认, 不做任何处理; 1: 视频按粤标处理
   * @return status - 结果状态值    0: 设备返回失败; 1: 请求成功; 2: 数据地址; -1: 设备没响应, 网关响应设备响应超时; -2: 网关没响应, 服务器响应设备响应超时
   * @return info - 结果描述
   * @return session - 回应哪个请求
   * @return codetype - 实际打开码流类型    0: 主码流; 1: 子码流
   * @return media_address.video_address - 视频地址
   * @return media_address.audio_address - 音频地址
   */
  realMediaReq(params, cb) {
    //实时音视频传输请求
    this._request.get(
      "v3/realMediaReq.do",
      {
        params: {
          ...params,
          // 是否是中国石油账号
          userFlag: !!(
            store?.state?.userRouter?.children?.find(
              (r) => r?.code === "zgsy"
            ) && store?.state?.userInfo?.name != "广东一立科技"
          ),
        },
      },
      (res) => {
        if (cb) {
          cb(res.data);
        }
      }
    );
  }

  /**
   * 下发实时视频控制命令
   * @param params.device - 终端号
   * @param params.channel - 通道号
   * @param params.ctrlcmd - 控制指令   0: 关闭音视频传输; 1: 切换码流; 2: 暂停; 3: 继续播放; 4: 关闭对讲
   * @param params.datatype - 数据类型    0: 关闭音视频(默认); 1: 只关闭音频; 2: 只关闭视频
   * @return status - 返回状态结果    0: 失败; 1: 成功; -1: 网关返回设备超时; -2: 服务器返回设备超时
   * @return info - 响应信息
   */
  realMediaCtrl(params, cb) {
    this._request.get(
      "v3/realMediaCtrl.do",
      {
        params: { ...params },
      },
      (res) => {
        if (cb) {
          cb(res.data);
        }
      }
    );
  }
  //#endregion

  //#region 回放音视频

  /**
   * 终端录像查询
   * @param params.device - 终端号
   * @param params.channel - 通道号
   * @param params.begintime - 查询开始时间(utc时间, 秒)
   * @param params.endtime - 查询结束时间(utc时间, 秒)
   * @param params.alarmSign - 报警标志   0: 无报警查询条件(所有视频); 其它参考 JT/T808-2011表18报警标志定义, JT/T1078 表13
   * @param params.datatype - 数据类型    0: 音视频; 1: 音频; 2: 视频; 3: 音频或视频
   * @param params.codetype - 码流类型    0: 所有码流; 1: 主码流; 2: 子码流
   * @param params.storgetype - 存储类型    0: 所有存储器; 1: 主存储器; 2: 灾备存储器; 240: 应急存储器(T7设备专用)
   * @param params.page - 查询页(只对老设备有效) 0: 第一页; 2: 第二页; [3,4 ....]
   * @return status - 返回结果状态    0: 设备返回失败; 1: 设备返回成功; -1: 网关返回设备响应超时; -2: 服务器返回设备响应超时
   * @return info - 结果描述信息
   * @return totalPage - 总页数   老设备FD协议, 1078设备总返回1
   * @return curPage - 当前页   老设备FD协议, 1078设备总返回1
   * @return records - 录像数量
   * @return {Array<file>} listFile - 录像文件集合
   * @return file.device - 终端号
   * @return file.channel - 通道号
   * @return file.begintime - 录像开始时间(毫秒)
   * @return file.endtime - 录像结束时间(毫秒)
   * @return file.alarmSign - 报警类型    0: 无报警
   * @return file.datatype - 数据类型   0: 音视频; 1: 音频; 2: 视频
   * @return file.codetype - 码流   1: 主码流; 2: 子码流
   * @return file.storgetype - 存储类型   1: 主存储器; 2: 灾备存储器
   * @return file.filesize - 文件大小(字节)
   * @return file.fileName - 文件名称(老设备有效)
   *
   */
  devRecordQuery(params, cb) {
    //终端录像查询
    this._request.get(
      "v3/devRecordQuery.do",
      {
        params: { ...params },
      },
      (res) => {
        if (cb) {
          cb(res.data);
        }
      }
    );
  }

  /**
   * 终端音视频回放传输请求
   * @param params.device - 终端号
   * @param params.channel - 通道号
   * @param params.datatype - 数据类型    0: 音视频; 1: 音频; 2: 视频; 3: 音频或视频; 4: 音视频和定位数据一起上传
   * @param params.codetype - 码流类型    0: 子码流或主码流; 1: 主码流; 2: 子码流
   * @param params.storgetype - 存储类型    0: 所有存储器; 1: 主存储器; 2: 灾备存储器
   * @param params.playway - 回放方式   0: 正常回放; 1: 快进回放; 2: 关键帧快退回放; 3: 关键帧播放; 4: 单帧播放; 5: bsj扩展回放方式, 设备放回GPS数据
   * @param params.playspeed - 播放倍数(playway为1或2时有效)   0: 无效; 1: 1倍; 2: 2倍; 3: 4倍; 4: 8倍; 5: 16倍
   * @param params.begintime - 开始时间(utc, 秒)
   * @param params.endtime - 结束时间(utc, 秒)
   * @param params.startoff - 文件名(16进制字符串)
   * @param params.devLinkIp - 服务器地址   专网客户的设备连接地址
   * @param params.SpecialSign - 特殊协议处理标志   0: 不处理; 1: 按粤标处理
   * @return status - 返回结构状态值    0: 设备返回失败; 1: 请求成功; 2: 数据请求地址; -1: 设备响应超时(网关); -2: 设备响应超时(服务器)
   * @return info - 结果描述
   * @return media_address.video_address - 视频地址
   * @return media_address.audio_address - 音频地址
   */
  devRecReq(params, cb) {
    //终端音视频回放传输请求
    this._request.get(
      "v3/devRecReq.do",
      {
        params: { ...params },
      },
      (res) => {
        if (cb) {
          cb(res.data);
        }
      }
    );
  }

  /**
   * 终端视频回放控制
   * @param params.device - 终端号
   * @param params.channel - 设备逻辑通道
   * @param params.ctrlcmd - 控制指令   0: 开始回放; 1: 暂停回放; 2: 结束回放; 3: 快进回放; 4: 关键帧快退挥发; 5: 拖放回放; 6: 关键帧播放
   * @param params.playspeed - 播放倍数(ctlcmd为3或4时有效)   0: 无效; 1: 1倍数; 2: 2倍数; 3: 4倍数; 4: 8倍数; 5: 16倍数
   * @param params.seekpos - 拖动回放位置(utc)    0: 一直回放; (ctlcmd为4时无效)
   * @return status - 返回结果状态值    0: 设备返回失败; 1: 请求成功; -1: 设备响应超时(网关); -2: 设备响应超时(服务器)
   * @return info - 结果描述
   */
  devRecCtrl(params, cb) {
    //终端视频回放控制
    this._request.get(
      "v3/devRecCtrl.do",
      {
        params: { ...params },
      },
      (res) => {
        if (cb) {
          cb(res.data);
        }
      }
    );
  }

  //#endregion

  //#region FTP上传

  /**
   * 终端文件上传请求(终端文件通过ftp上传到服务器)
   * @param params.device - 终端号
   * @param params.channel - 通道号
   * @param params.begintime - 开始时间(utc,秒)
   * @param params.endtime - 结束时间(utc, 秒)
   * @param params.alarmSign - 报警标志   0: 无报警; 其他参考JT/T808-2011表18报警标志定义, JT/T1078 表13;
   * @param params.datatype - 数据类型    0: 音视频; 1: 音频; 2: 视频; 4: 音频或视频
   * @param params.codetype - 码流类型    0: 所有码流; 1: 主码流; 2: 子码流
   * @param params.storgetype - 存储器类型    0: 所有存储器; 1: 主存储器; 2: 灾备存储器
   * @param params.taskCondition - 执行任务条件   参考协议
   * @return status - 返回结果状态    0: 失败; 1: 成功; -1: 设备超时(网关); -2: 设备超时(服务器)
   * @return info - 结果描述
   * @return serial - 设备返回流水号, 用于ftp上传控制命令
   */
  cmdDevFTPDown(params, cb) {
    this._request.get(
      "v3/cmdDevFTPDown.do",
      {
        params: { ...params },
      },
      (res) => {
        if (cb) cb(res.data);
      }
    );
  }

  /**
   * 终端文件上传控制
   * @param params.device - 终端号
   * @param params.serial - 终端文件上传请求返回的流水号
   * @param params.cmdType - 控制命令类型   0: 暂停; 1: 继续; 2: 取消
   */
  cmdDevFTPCtrl(params, cb) {
    this._request.get(
      "v3/cmdDevFTPCtrl.do",
      {
        params: { ...params },
      },
      (res) => {
        if (cb) cb(res.data);
      }
    );
  }
  //#endregion

  /**
   * NOTE: 未实现
   */
  serRecordQuery(params, cb) {
    //服务器录像查询
    this._request.get(
      "v3/serRecordQuery.do",
      {
        params: { ...params },
      },
      (res) => {
        if (cb) {
          cb(res.data);
        }
      }
    );
  }

  /**
   * 本地录像查询
   * @param params.diskpath - 磁盘路径    注册时回复中的其一
   * @param params.channel -  通道号    设备通道号
   * @param params.begintime - 开始时间(utc, 秒)
   * @param params.endtime - 结束时间(utc, 秒)
   * @return status - 返回结果 0: 失败; 1: 成功
   * @return info - 结果描述
   * @return records - 录像数量
   * @return {Array<file>} recordfile - 录像文件
   * @return file.filepath - 文件路径
   * @return file.channel - 通道号
   * @return file.begintime - 录像开始时间
   * @return file.endtime - 录像结束时间
   * @return file.datatype - 数据类型   0: 音视频; 1: 音频; 2: 视频
   * @return file.filesize - 文件大小(字节)
   */
  locRecordQuery(params, cb) {
    //本地录像查询
    this._request.get(
      "v3/locRecordQuery.do",
      {
        params: { ...params },
      },
      (res) => {
        if (cb) cb(res.data);
      }
    );
  }

  /**
   * description
   */
  locDiskQuery(params, cb) {
    //本地录像时间查询
    this._request.get(
      "v3/locDiskQuery.do",
      {
        params: { ...params },
      },
      (res) => {
        if (cb) cb(res.data);
      }
    );
  }

  serRecReq(params, cb) {
    //服务器音视频回放传输请求
    this._request.get(
      "v3/serRecReq.do",
      {
        params: { ...params },
      },
      (res) => {
        if (cb) {
          cb(res.data);
        }
      }
    );
  }

  serRecCtrl(params, cb) {
    //服务器回放视频控制
    params.protocolType = params.protocolType || 1;
    this._request.get(
      "v3/serRecCtrl.do",
      {
        params: { ...params },
      },
      (res) => {
        if (cb) {
          cb(res.data);
        }
      }
    );
  }

  locRecReq(params, cb) {
    //本地音视频回放传输请求
    this._request.get(
      "v3/locRecReq.do",
      {
        params: { ...params },
      },
      (res) => {
        if (cb) cb(res.data);
      }
    );
  }

  locRecCtrl(params, cb) {
    //本地回放视频控制
    this._request.get(
      "v3/locRecCtrl.do",
      {
        params: { ...params },
      },
      (res) => {
        if (cb) cb(res.data);
      }
    );
  }

  locTranMp4(params, cb) {
    this._request.get(
      "v3/locTranMp4.do",
      {
        params: { ...params },
      },
      (res) => {
        if (cb) cb(res.data);
      }
    );
  }

  locExportFile(params, cb) {
    this._request.get(
      "v3/locExportFile.do",
      {
        params: { ...params },
      },
      (res) => {
        if (cb) cb(res.data);
      }
    );
  }

  locExportCtrl(params, cb) {
    //查询导出状态进度
    this._request.get(
      "v3/locExportCtrl.do",
      {
        params: { ...params },
      },
      (res) => {
        if (cb) cb(res.data);
      }
    );
  }

  locExportState(params, cb) {
    //本地视频导出状态查询
    this._request.get(
      "v3/locExportState.do",
      {
        params: { ...params },
      },
      (res) => {
        if (cb) cb(res.data);
      }
    );
  }

  gaDevDownReq(params, cb) {
    this._request.get(
      "v3/gaDevDownReq.do",
      {
        params: { ...params },
      },
      (res) => {
        if (cb) cb(res.data);
      }
    );
  }

  gaDevDownCtrl(params, cb) {
    this._request.get(
      "v3/gaDevDownCtrl.do",
      {
        params: { ...params },
      },
      (res) => {
        if (cb) cb(res.data);
      }
    );
  }

  jtVodToHttpDownReq(params, cb) {
    this._request.get(
      "v3/jtVodToHttpDownReq.do",
      {
        params: { ...params },
      },
      (res) => {
        if (cb) cb(res.data);
      }
    );
  }

  /**
   * 注册指令
   */
  register(params, cb) {
    //注册
    this._request.get(
      "v3/register.do",
      {
        params: { ...params },
      },
      (res) => {
        if (cb) cb(res.data);
      }
    );
  }

  /**
   * 心跳指令
   */
  cmdReqHeart(params, cb) {
    //心跳
    this._request.get(
      "v3/cmdReqHeart.do",
      {
        params: { ...params },
      },
      (res) => {
        if (cb) cb(res.data);
      }
    );
  }

  /**
   * 响应查岗指令
   */
  cmdLookUp(params, cb) {
    this._request.get(
      "v3/cmdLookUp.do",
      {
        params: { ...params },
      },
      (res) => {
        if (cb) cb(res.data);
      }
    );
  }

  /**
   * 视频服务连接成功回调
   */
  _onRequestOpen(e) {
    const params = {
      // 平台唯一key, 同登录获取
      userkey: this._userkey,
      // 用户级别, 默认1
      level: this._userLevel,
    };

    if (this._platform) {
      params.platform = this._platform;
    }

    if (e && e.flag == 1) {
      // 连接成功后注册
      this.register(params, (res) => {
        if (this._registercb) this._registercb(res);
      });
    }
    if (this._opencb) this._opencb(e);
  }

  /**
   * websocket断开回调
   */
  _onRequestClose(e) {
    if (this._closecb) this._closecb(e);
  }

  /**
   * 接受websocket消息, 回调
   */
  _onRequestMessage(data) {
    if (this._messagecb) this._messagecb(data);
  }

  /**
   * 心跳回调
   */
  _onRequestHeart() {
    if (this._heartcb) this._heartcb();
  }
}

export default ByskRequest;
