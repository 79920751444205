import { uuid } from '@/utils/uuid';

/**
 * 视频服务响应状态码(收到响应后根据session清除请求信息)
 * status定义
 * 0: 请求失败；
 * 1: 请求成功
 * 2: 响应视频地址成功 //请求信息 不清除对应的请求
 * -1: 设备备没有响应，网关返回设备响应超时
 * -2: 网关没有响应，服务器返回设备响应超时
 * -3: 设备录像查询，当前有用户在查
 * -4: 设备回放有其他高级级别用户再看
 * -6: 设备欠压
 * -7: 设备休眠
 * -8: 设备唤醒中
 * -101: WebSocket未连接
 * -102: 请求超时
 * 
 * 16: 高清标清切换
 * 17: 视频服务返回用户当前流量已经耗尽
 * 18: 设备发起主动对讲
 * 7001: 本地播放GPS信息通知
 * 19: 视频回放高级别用户通知
 * 20: 实时对讲高级别用户通知   
 * 21: 切换链路通知
 */

/**
 * CmdDownType定义
 * 30: 查岗
 * 130: 报警督办
 */

class ByskWebSocket {
  constructor(config) {
    this._requestData = [];
    this._ith = null; // setInterval 重连WebSocket标识符
    this._ithot = null; // 请求超时检测
    this._wsid = config.wsid || 'byskws';
    this._baseURL = config.baseURL; // 视频服务地址
    this._username = config.username; // 登录用户名
    this._openCallback = config.open; // 连接成功回调
    this._closeCallback = config.close; // 连接断开回调
    this._heartCallback = config.heart; // 心跳回调
    this._messageCallback = config.message; // 接受消息回调
    this._token = config.token;
    this._reconnectCount = 0; // 重连次数, 连接成功后会重置
    this._reconnectEnable = typeof config.reconnectEnable === 'undefined' ? true : config.reconnectEnable;
    this._connectWebSocket();

  }

  /**
   * 销毁链路
   */
  destroy() {
    clearInterval(this._ithot);
    clearInterval(this._ith);

    if (this.connecting || this.connected) {
      // console.log(`${this._wsid} ${this._baseURL} 主动断开`);
      this._ws.close();
      this._ws.onopen = null;
      this._ws.onclose = null;
      this._ws.onmessage = null;
      this._ws.onerror = null;
    }
    this._ws = null;
  }

  /**
   * 下发请求(有格式要求)
   */
  get(url, config, cb) {
    let { params, timeout } = config;

    // 设置默认登录用户名
    params.username = params.username || this._username;
    // 设置默认客户端类型 1: PC; 2: APP; 3: 809上级平台; 3: 微信小程序; 4: 
    params.clientType = params.clientType >= 0 ? ~~params.clientType : 1;
    // 有前端生成的唯一ID
    params.token = params.token || this._token;  // 一个ws链接一个token
    // 每次下发唯一, 服务端会返回
    params.session = params.session || Date.now() + uuid().substr(0, 16);

    // 设置超时时间
    timeout = timeout ? timeout : 1000 * 60;
    // 记录发出请求时间
    const wssenttime = new Date().getTime();
    // 保存请求, 将请求的回调(cb)存储, 服务端返回结果时, 通过此回调反馈给调用方
    this._requestData.push({ url, config: { params, timeout }, wssenttime, cb });

    let data = url[0] === "/" ? url : "/" + url;
    // data += "?" + encodeURIComponent(Object.entries(params).map(([key, val]) => `${key}=${val}`).join('&'))
    // 将对象params变为 URL params 形式
    data += "?" + Object.entries(params).map(([key, val]) => `${key}=${val}`).join('&');
    // console.log(`${this._wsid} send ${data}`);

    if (this.connected) {
      // 发送消息给视频服务
      this._ws.send(data);
      return params.session;
    } else {
      // websocket 未连接, 则直接回复请求未连接
      this._wsResponse(params.session, { status: -101, info: this._baseURL + '未连接' });
      // console.log(this._baseURL + '未连接');
    }
    return null;
  }

  /**
   * 发送消息(通用), 无数据格式限制, 无回调
   */
  send(buffer) {
    return new Promise((reslove, reject) => {
      console.log(this, this.connected);
      if (this.connected) {
        this._ws.send(buffer);
        console.log(`${this._baseURL}send`, buffer);
      } else {
        reslove({ status: -101, info: this._baseURL + '未连接' });
        console.log(this._baseURL + '未连接');
      }
    });
  }

  /**
   * 清除指令请求命令
   */
  killRequest(requestId) {
    let i = this._requestData.findIndex(p => p.config.params.session == requestId);
    if (i > -1) {
      this._requestData.splice(i, 1);
    }
  }


  /**
   * websocket是否已经连接
   */
  get connected() {
    return this._ws && this._ws.readyState === 1;
  }

  /**
   * websocket是否正在连接
   */
  get connecting() {
    return this._ws && this._ws.readyState === 0;
  }

  /**
   * 通过请求Id, 回应请求
   */
  _wsResponse(requestId, data) {
    // 从已经下发的请求中找到指令的请求
    let i = this._requestData.findIndex(p => p.config.params.session === requestId);
    let reqObj = this._requestData[i];
    if (reqObj) {

      // 除了响应状态为2, 3，其他响应状态都清除对应的请求
      // 状态为2, 表示获取到播放地址, 此时整个请求还未完成
      if (!(data.status == 2 || data.status == 3)) {

        // 请求流程完成, 删除下发的请求
        this._requestData.splice(i, 1);
      }
      if (reqObj && reqObj.cb) {
        try {
          // 通过回调响应请求
          reqObj.cb({ data });
        } catch (err) { }
      }
    }
    return reqObj;
  }

  /**
   * 响应超时检测
   */
  _requestTimeout() {
    clearInterval(this._ithot);
    this._ithot = setInterval(() => {
      let t_requestData = this._requestData.map(p => p);
      t_requestData.forEach(request => {
        if (request.config && request.config.timeout && (new Date().getTime() - request.wssenttime > request.config.timeout)) {
          // 视频服务未在超时时间内容响应, 则直接响应超时
          this._wsResponse(request.config.params.session, {
            status: -102,
            info: '响应超时'
          });
        }
      });
      t_requestData = null;
    }, 1000 * 5);
  }

  /**
   * 连接视频服务
   */
  _connectWebSocket() {
    // 建立链路前先消耗,
    this.destroy();
    // 建立链路
    let ws = this._ws = new WebSocket(this._baseURL);
    // 注册websocket连接成功回调
    ws.onopen = this._onWebSocketOpen.bind(this);
    // 注册websocket连接成功断开回调
    ws.onclose = this._onWebSocketClose.bind(this);
    // 注册websocket接受消息回调
    ws.onmessage = this._onWebSocketMessage.bind(this);
    // 注册websocket发生异常回调
    ws.onerror = this._onWebSocketError.bind(this);

    // 开启心跳, 重连机制
    this._ith = setInterval(() => {
      if (!(this.connecting || this.connected)) {
        // 如果没有连接, 则重连
        if (this._reconnectEnable) {
          this._reconnectCount++; // 重连次数
          this._connectWebSocket();
        }
      } else {
        // 如果已经连接, 则心跳
        if (this._heartCallback) this._heartCallback();
      }
    }, 1000 * 45);
  }

  /**
   * 接受视频服务消息
   */
  _onWebSocketMessage(e) {

    console.log(`${this._wsid} ${this._baseURL} recv: ${e.data}`);
    if (typeof e.data === 'string') {
      try {
        // 字符串 转 JOSN 对象, 过滤调非法字符
        let obj = JSON.parse(e.data.replace(/\\/g, '\\\\'));
        if ([
          16, //高标清切换
          17, //视频服务返回用户当前流量已经耗尽
          18, //设备发起主动对讲
          7001, //本地播放GPS信息通知
          19, //视频回放高级别用户通知
          20, //实时对讲高级别用户通知
          21, //切换链路通知
        ].includes(obj.status)
          || [
            30, //查岗
            130, //报警督办
          ].includes(obj.CmdDownType)) {
          // 通知回调
          if (this._messageCallback) this._messageCallback(obj);
        } else {

          // 响应请求
          const responseEanble = this._wsResponse(obj.session, obj);
          if (!responseEanble) { // 如果没有可响应的请求, 则直接通知回调
            if (this._messageCallback) this._messageCallback(obj);
          }
        }
      } catch (err) {
        console.log(err);
        if (this._messageCallback) this._messageCallback(e.data);
      }
    }
    if (e.data instanceof ArrayBuffer) {
      let buffer = e.data;
    }
  }

  /**
   * websocket连接成功回调
   */
  _onWebSocketOpen(e) {
    console.log(`${this._wsid} ${this._baseURL} 连接成功`);
    this._reconnectCount = 0;
    if (this._openCallback) {
      this._openCallback({
        flag: 1,
        msg: `${this._baseURL}连接成功`
      });
    }
    this._requestTimeout();
  }

  /**
   * weboscket连接断开回调
   */
  _onWebSocketClose(e) {
    console.log(`${this._wsid} ${this._baseURL} 断开连接`);
    // > 2: 是在网络异常的情况下, 防止频繁触发断开事件
    if (this._reconnectCount > 2 && this._closeCallback) {
      this._closeCallback({
        flag: 1,
        msg: `${this._baseURL}断开连接`
      });
    }
  }

  /**
   * websocket发生异常回调
   */
  _onWebSocketError(e) {
    console.log(`${this._wsid} ${this._baseURL} 发生异常`);
    console.log(e);
    this._closeCallback({
      flag: 1,
      msg: `${this._baseURL} 发生异常`
    });
  }
}

export default ByskWebSocket;